<template>
<section class="flag module">

  <div v-if="!expanded">
    <b-button variant="outline-primary"
              aria-label="Spotted an error?"
              @click="expanded=!expanded">Spotted an error?</b-button>
  </div>

  <template v-else>
    <header>
      <span>
        report errors
      </span>
    </header>
    <b-form v-if="!onSuccess ">
      <b-textarea v-model="comment"
                  v-if="!isSending"
                  placeholder="Please inform us of any errors on this page so that we may correct them."></b-textarea>
      <b-button v-if="!isSending"
                size="md"
                aria-label="Flag error"
                @click="sendToGoogleForm">Flag Error</b-button>
      <b-spinner v-else
                 type="grow"></b-spinner>
    </b-form>
    <div v-else>
      <p> Thank you for your submission!</p>
      <b-button aria-label="Flag another error"
                size="md"
                @click="redisplayButton">Flag Another Error</b-button>
    </div>


  </template>

</section>
</template>

<script>
import { API } from '@/scripts/Api.js';

export default {
  name: "Flag",
  components: {},
  props: [ "service" ],
  data() {
    return {
      expanded: false,
      isSending: false,
      comment: "",
      onSuccess: false
    }
  },
  computed: {},
  methods: {
    redisplayButton() {
      this.comment = "",
        this.isSending = false,
        this.onSuccess = false
    },
    sendToGoogleForm() {
      this.isSending = true
      let url_params = {
        params: {
          service_name: this.service.name,
          comment: this.comment,
          service_id: this.service.id,
          timestamp: new Date()
        }
      }
      API.sendErrorToGoogle( url_params )
        .then( result => {
          console.log( "google error result: ", result )
          this.isSending = false
          this.onSuccess = true
        } )
        .catch( error => {
          this.error = error
          this.isSending = false
        } )
    }
  }
};
</script>
<style scoped lang="scss">
.flag {
    @include module;
    width: 100%;
    form {
        display: grid;
        grid-gap: 1rem;
        textarea {
            width: 100%;
        }

        button {
            justify-self: start;
        }

    }

}
</style>
