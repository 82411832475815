<template>
<div class="search-options"
     :class="compact ? 'compact' : 'large'"
     v-if="hasAddress">

  <div>
    <div class="smallcaps">Services</div>
    <b-form-checkbox-group id="primary_types"
                           v-model="primary"
                           :size="size"
                           stacked
                           switches
                           value-field="id"
                           :options="service_primary_types"
                           :disabled="isSearching"
                           name="primary_types" />

  </div>
  <div>
    <div class="smallcaps">Filters</div>
    <b-form-checkbox-group id="secondary_types"
                           v-model="secondary"
                           :size="size"
                           stacked
                           switches
                           value-field="id"
                           :options="service_secondary_types"
                           :disabled="isSearching"
                           name="secondary_types" />
  </div>

</div>
</template>

<script>
export default {
  name: "SearchOptions",
  mixins: [],
  props: {
    compact: { type: Boolean, default: false }
  },
  data() {
    return {}
  },
  computed: {
    primary: {
      get() {
        return this.store_search_options.primary_types
      },
      set( value ) {
        this.$store.dispatch( "updatePrimarySearchOptions", value );
      }
    },
    secondary: {
      get() {
        return this.store_search_options.secondary_types
      },
      set( value ) {
        this.$store.dispatch( "updateSecondarySearchOptions", value );
      }
    },
    size() {
      return this.compact ? "md" : "md"
    },
  }
};
</script>
<style scoped lang="scss">
.search-options {
    display: grid;
    grid-column-gap: var(--p5);
    grid-row-gap: var(--p7);
    grid-template-columns: 1fr 1fr 1fr;

    &.compact {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
    }

    > .custom-control {
        display: grid;

        * {
            user-select: none;
            cursor: pointer;
        }

        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }

    #secondary_types {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(10rem, auto));
    }

    .mobile & {
        grid-template-columns: 1fr;
        grid-auto-flow: row;

        #secondary_types {
            display: grid;
            grid-template-columns: 1fr;
        }
    }
}
</style>
