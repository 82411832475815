<template>
<section class="accessibility module"
         v-if="hasAny">
  <header>
    <span>
      Accessibility
    </span>
  </header>

  <ul>
    <li v-for="entry in accessibility_for_disabilities"
        :key="entry.id">
      <div>
        {{entry.details}}
      </div>
    </li>
  </ul>

</section>
</template>

<script>
export default {
  name: "Accessibility",
  components: {},
  props: [ "service" ],
  data() {
    return {
      accessibility_for_disabilities: []
    }
  },
  watch: {
    service: {
      immediate: true,
      handler( newVar, oldVar ) {
        if ( newVar && newVar != oldVar ) {
          this.$API.fetchSpecificLocation( this.service.service_at_location[ 0 ].location_id ).then( result => {
            this.accessibility_for_disabilities = result[ 0 ].accessibility_for_disabilities
          } )
        }
      }
    }
  },
  computed: {
    hasAny() {
      return this.accessibility_for_disabilities.length > 0
    },
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.accessibility {
    @include module;
    li {
        display: grid;
        grid-gap: 1rem;

    }

}
</style>
