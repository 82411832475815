<template>
<div class="taxonomy module"
     v-if="hasTaxonomies">
  <div class="tag"
       v-for="tag in filledIn"
       :key="tag.id">
    Programs: {{tag.text}}
  </div>

</div>
</template>

<script>
export default {
  name: "Taxonomy",
  components: {},
  props: [ "service" ],
  data() {
    return {}
  },
  computed: {
    taxonomies() {
      return this.service.service_taxonomy.map( a => a.taxonomy_id )
    },
    hasTaxonomies() {
      return this.taxonomies.length > 0
    },
    filledIn() {
      return this.taxonomies.map( a => {
        return this.taxonomy_codes.find( b => b.id == a )
      } )
    }
  },
};
</script>
<style scoped lang="scss">
.taxonomy {
    @include module;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-gap: 1rem;
    justify-content: start;

    * {
        color: white;
    }

    .tag {
        // opacity: 0.5;
    }

    &.print {
        grid-gap: 0;
        grid-auto-flow: row;
        font-size: 0.7rem;

        .tag {
            opacity: 1;
        }
    }
}
</style>
