<template>
<section class="calendar module"
         :class="type"
         v-if="hasSlots">
  <header>
    <span>
      {{title}}
    </span>
  </header>

  <ul>
    <li class="date"
        v-for="day in slots"
        :key="day.id">

      <template v-if="type=='regular'">
        <div class="day"><span>{{day.label}}</span></div>
        <div class="slots">
          <div class="slot"
               v-for="slot in day.slots"
               :key="slot.id">
            <div class="slot-time">
              <div class="start">
                {{opens(slot, true, true, false)}}
              </div>
              <div class="to">-</div>
              <div class="end">
                {{closes(slot, true, true, true)}}
              </div>
            </div>
            <div v-if="slot.details" class="slot-time">
              <div class="to">
                <span>{{slot.details}}</span>
              </div>
            </div>
            <!--
            <div class="slot-details">
              {{slot.details}}
            </div>
            -->
          </div>
        </div>
          <!--
        <div class="day">
          <div v-for="slot in day.slots"
               :key="slot.id">
               <span>{{slot.details}}</span>
          </div>
        </div>
          -->
      </template>
      <template v-else-if="spanDates(day).length > 0">

        <div class="day">
          <span>{{smartRange(day)}}</span>
        </div>

        <div class="slots">
          <div class="slot holiday"
               v-for="slot in day.slots"
               :key="slot.id"
               :class="[{'closed':slot.isClosed}]">
            <template v-if="slot.isClosed">
              <div>
                Closed
              </div>
            </template>
            <template v-else>
              <div class="start">
                {{opens(slot, true, true, false)}}
              </div>
              <div class="to">-</div>
              <div class="end">
                {{closes(slot, true, true, true)}}
              </div>
            </template>
          </div>
        </div>
      </template>

    </li>
  </ul>
</section>
</template>

<script>
import Mixins from "@/scripts/Mixins"

export default {
  name: "Calendar",
  mixins: [ Mixins ],
  components: {},
  props: [ 'service', 'type' ],
  data() {
    return {}
  },
  computed: {
    title() {
      if ( this.type == 'regular' ) {
        return 'Regular hours'
      } else {
        return 'Holiday hours'
      }
    },
    slots() {
      if ( this.type == 'regular' ) {
        return this.service.weeklyCalendar
      } else {
        return this.service.holidayCalendar.filter( ( v, i, a ) => a.findIndex( t => t.date === v.date && t.opens_at === v.opens_at ) === i );
      }
    },
    hasSlots() {
      return this.slots && this.slots.length > 0
    },
  },
  methods: {
    spanDates( day ) {
      let span = day.slots[ 0 ].span
      if ( span.start_date && span.end_date && span.start_date != span.end_date ) {
        return [ this.asDate( span.start_date ), this.asDate( span.end_date ) ]
      } else if ( span.start_date ) {
        return [ this.asDate( span.start_date ) ]
      } else {
        return []
      }
    },
    smartRange( day ) {
      let span = this.spanDates( day )
      if ( span.length == 2 && span[ 0 ] == span[ 1 ] ) {
        // Same date
        return span[ 0 ]
      } else if ( span.length == 2 && span[ 0 ].split( " " )[ 0 ] == span[ 1 ].split( " " )[ 0 ] ) {
        // Same month
        return span[ 0 ] + "-" + span[ 1 ].split( " " )[ 1 ]
      } else if ( span.length == 2 ) {
        // All different
        return span[ 0 ] + " - " + span[ 1 ]
      }

    }
  }
};
</script>
<style scoped lang="scss">
.calendar {
    @include module;

    .date {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr 2fr;
        grid-gap: 1rem;

        .day {
            display: grid;
            grid-gap: 0.5rem;
            grid-auto-flow: row;
            justify-content: start;
            font-weight: bold;
        }
        .slot {
            * {
                display: inline-block;
                margin-right: 0.4rem;
            }
            &.closed {
                * {
                    color: $danger;
                }
            }
        }
    }

    .to {
        opacity: 0.5;
    }

    .slots {
        display: grid;
        .slot {
            display: grid;
            grid-auto-flow: row;
        }
        .slot-time {
            display: grid;
            grid-template-columns: auto auto auto;
            align-items: center;
            grid-auto-flow: column;
            justify-content: start;
            grid-gap: 0.2rem;
        }
    }

    &.print {
        font-size: 0.7rem;

        header {
            display: none;
        }

        .date {
            grid-template-columns: 1fr;
            grid-auto-flow: row;
            grid-gap: 0;

            .slots {
                grid-auto-flow: row;
                align-content: start;

            }
        }
    }

}
</style>
