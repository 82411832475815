import {
  getBoundingBox,
  distanceTo,
  toLatLon,
  toLatLng
} from "geolocation-utils";
import {Loader} from "google-maps";
import Store from "@/store";
const loader = new Loader("AIzaSyBrjPcFB2beiXFC_HXVRafyyo04KKuu-pE");

export class Location {
  constructor(options = {}) {
    this.automatically_detecting = false;
    this.automatically_detecting_error = null;
    this.user_location_status = null;
    this.directions_service = null;
    this.directions_renderer = null;
    this.geocoder = null;
    this.google = null;
    this.origin = Store.getters.store_search_address;
    this.destination = options.destination || null;
    this.mapElement = options.mapElement || null;
    this.preview = options.preview || false;
    this.map = null;
    if (options.simple) {
      return this;
    }
  }

  initGoogle() {
    return new Promise(resolve => {
      this.loader = loader;
      if (!this.google) {
        this.loader.load().then(result => {
          this.google = result;
          this.geocoder = new this.google.maps.Geocoder();
          this.directions_service = new this.google.maps.DirectionsService();
          this.directions_renderer = new this.google.maps.DirectionsRenderer();
          resolve(this);
        });
      } else {
        resolve(this);
      }
    });
  }

  mapOptions() {
    let center = this.destination
      ? new this.google.maps.LatLng(this.destination.lat, this.destination.lng)
      : new this.google.maps.LatLng(39, -102); // Middle of USA

    let zoom = this.origin ? 15 : 4;
    return {
      center: center,
      zoom: zoom,
      draggable: true,
      streetViewControl: this.preview ? false : true,
      zoomControl: this.preview ? false : true,
      scaleControl: this.preview ? false : true,
      mapTypeControl: false,
      styles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#ededf1"
            }
          ]
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#3d4569"
            }
          ]
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#f5f5f5"
            }
          ]
        },
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd"
            }
          ]
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee"
            }
          ]
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575"
            }
          ]
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5"
            }
          ]
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e"
            }
          ]
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff"
            }
          ]
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "road.arterial",
          stylers: [
            {
              // "visibility": "off"
            }
          ]
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575"
            }
          ]
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#d1d3db"
            }
          ]
        },
        {
          featureType: "road.highway",
          elementType: "labels",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161"
            }
          ]
        },
        {
          featureType: "road.local",
          stylers: [
            {
              // "visibility": "off"
            }
          ]
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e"
            }
          ]
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5"
            }
          ]
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee"
            }
          ]
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#acafbf"
            }
          ]
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e"
            }
          ]
        }
      ]
    };
  }

  async loadMap() {
    // console.log("LOC", "loadMap");

    if (!this.google) {
      await this.initGoogle();
    }

    this.map = new this.google.maps.Map(this.mapElement, this.mapOptions());
    this.directions_renderer.setMap(this.map);

    if (this.origin) {
      this.calcRoute().then(result =>
        this.directions_renderer.setDirections(result)
      );
    } else {
      this.showMarker();
    }
  }

  async loadResearchMap() {
    // console.log("LOC", "loadResearchMap");

    if (!this.google) {
      await this.initGoogle();
    }
    let options = this.mapOptions();
    options.zoom = 5;
    this.map = new this.google.maps.Map(this.mapElement, options);
    let infowindow = new this.google.maps.InfoWindow();
    for (let m of Store.getters.research_services) {
      let marker = new this.google.maps.Marker({
        position: new this.google.maps.LatLng(m.location.lat, m.location.lng),
        map: this.map
      });
      this.google.maps.event.addListener(marker, "click", function() {
        console.log("m", m);
        infowindow.setContent(`<div style="max-width:20rem">
        <h5>${m.name}</h5>
        </div>`);
        infowindow.open(this.map, this);
      });
    }
  }

  /*
  Use the Google Maps api to turn a location string (address)
  into a full Google Maps location object, with geolocation data
  and other useful fields. On success, update the store with the
  FIRST result.
  */
  async getAddress(address) {
    if (!this.google) {
      await this.initGoogle();
    }

    return new Promise((resolve, reject) => {
      if (!address || address == "") {
        reject();
        return;
      }
      this.geocoder.geocode(
        {
          address: address,
          componentRestrictions: {
            country: "US"
          }
        },
        success => {
          // console.log("LOC", "getAddress", address);
          resolve(success[0]);
        }
      );
    });
  }

  getAddressFromCoords(coords) {
    // console.log("LOC", "getAddressFromCoords", coords);
    return new Promise(resolve => {
      this.geocoder.geocode(
        {location: {lat: coords.latitude, lng: coords.longitude}},
        resolve
      );
    });
  }

  /*
  Use the browser's location capability to get a rough geolocation
  of the user's browser and then convert that into a real address using
  the Google Maps api. On success, update the store with the FIRST result.
  */
  async getAddressAutomatically() {
    // console.log("LOC", "getAddressAutomatically");
    this.automatically_detecting = true;
    this.automatically_detecting_error = null;

    if (!this.google) {
      await this.initGoogle();
    }

    return new Promise((resolve, reject) => {
      this.getCoordsFromBrowser()
        .then(position => {
          this.automatically_detecting = false;
          if (position && position.coords) {
            this.getAddressFromCoords(position.coords).then(address => {
              let bestMatch = address[0];
              Store.dispatch(
                "updateSimpleAddress",
                bestMatch.formatted_address
              );
              resolve();
            });
          } else {
            this.automatically_detecting_error =
              "Geolocation is not supported by this browser.";
            reject(this.automatically_detecting_error);
          }
        })
        .catch(error => {
          this.automatically_detecting = false;
          var msg = null;
          switch (error.code) {
            case error.PERMISSION_DENIED:
              msg = "User denied the request for Geolocation.";
              break;
            case error.POSITION_UNAVAILABLE:
              msg = "Location information is unavailable.";
              break;
            case error.TIMEOUT:
              msg = "The request to get user location timed out.";
              break;
            case error.UNKNOWN_ERROR:
              msg = "An unknown error occurred.";
              break;
          }
          this.automatically_detecting_error = msg;
          reject(msg);
        });
    });
  }

  getAddressAndBoundingBox(simple_address) {
    let search_address, search_bounds;
    let self = this;
    return new Promise((resolve, reject) => {
      if (!simple_address) {
        reject("No address");
      }
      self
        .getAddress(simple_address)
        .then(result => {
          search_address = result;
          this.setSearchBoundary(search_address)
            .then(box => {
              search_bounds = box;
              resolve({search_address, search_bounds});
            })
            .catch(error => reject(error));
        })
        .catch(error => reject(error));
    });
  }

  getCoordsFromBrowser() {
    // console.log("LOC", "getCoordsFromBrowser");
    let options = {
      enableHighAccuracy: true,
      maximumAge: 600000
    };
    if (navigator.geolocation) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
      );
    } else {
      return new Promise(resolve => resolve({}));
    }
  }

  calcDistanceToService() {
    let from = this.toSimpleLatLon(
      Store.getters.store_search_address.geometry.location
    );
    let to = this.toSimpleLatLon(this.destination);
    if (from && to) {
      // console.log("LOC", "calcDistanceToService");
      return distanceTo(from, to);
    } else {
      return null;
    }
  }

  async calcRoute(travelMode) {
    // console.log("LOC", "calcRoute", travelMode);
    if (!this.google) {
      await this.initGoogle();
    }
    return new Promise((resolve, reject) => {
      let from = Store.getters.store_search_address;
      let originLatLng = this.toSimpleLatLng(from.geometry.location);
      let destinationLatLng = this.toSimpleLatLng(this.destination);
      var request = {
        origin: originLatLng,
        destination: destinationLatLng,
        travelMode: travelMode
          ? travelMode
          : Store.getters.transportation_preference
      };

      this.directions_service.route(request, (result, status) => {
        // console.warn("LOC", "Ping Google Maps API");
        if (status == "OK") {
          resolve(result);
        } else {
          reject(status);
        }
      });
    });
  }
  /*
  We don't have an origin to show a route, so instead we just show
  the destination as a marker.
  */
  async showMarker() {
    if (!this.google) {
      await this.initGoogle();
    }

    let marker = new this.google.maps.Marker({
      position: new this.google.maps.LatLng(
        this.destination.lat,
        this.destination.lng
      )
    });
    marker.setMap(this.map);
  }

  setSearchBoundary(real_address) {
    return new Promise((resolve, reject) => {
      // console.log("LOC", "setSearchBoundary");
      if (real_address) {
        // console.log("LOC", "setSearchBoundary");
        let center = real_address.geometry.location;
        let locations = [this.toSimpleLatLng(center)];
        let padding = Store.getters.store_search_options.search_radius;
        /*
        `getBoundingBox` returns a bounding box from all the locations passed in,
        and adds whatever padding (in meters) to that box. In our case we will
        only ever pass in one location (the center).
        */
        let box = getBoundingBox(locations, padding);
        if (box) {
          resolve(box);
        } else {
          reject();
        }
      }
    });
  }

  toSimpleLatLng(object) {
    if (typeof object.lat === "function") {
      return toLatLng({lat: object.lat(), lng: object.lng()});
    } else {
      return toLatLng({lat: object.lat, lng: object.lng});
    }
  }

  toSimpleLatLon(object) {
    if (typeof object.lat === "function") {
      return toLatLon({lat: object.lat(), lng: object.lng()});
    } else {
      return toLatLon({lat: object.lat, lng: object.lng});
    }
  }
}
