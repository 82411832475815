// PDF Generator
import html2pdf from "html2pdf.js";
export default {
  created() {},
  data() {
    return {};
  },
  methods: {
    async downloadPDF() {
      this.$gtag.event("download_pdf", {
        event_category: "pdf",
        event_label: this.store_search_address.formatted_address,
        value: 1
      });

      var opt = {
        margin: 0.2,
        filename: "Find Food - " + new Date().toDateString() + ".pdf",
        image: {type: "jpeg", quality: 1},
        html2canvas: {scale: 2},
        jsPDF: {unit: "in", format: "letter", orientation: "landscape"}
      };

      let element = this.$refs["page"];
      html2pdf()
        .set(opt)
        .from(element)
        .save();
    }
  }
};
