<template>
<section class="direction-item expanded">

  <b-spinner class="m-5 m-auto"
             type="grow"
             v-if="isLoading"></b-spinner>

  <div class="route"
       v-if="hasAny">
    <Icon :type="travelmode.toLowerCase()"></Icon>
    <span class="summary">{{routeDuration}}</span>
  </div>

  <ul class="steps"
      v-if="hasAny">
    <li class="step"
        v-for="step in routeLeg.steps"
        :key="step.id">
      <span v-html="step.instructions">{{step.instructions}}</span>
    </li>
  </ul>

  <b-alert :show="!hasAny"
           variant="warning">No routes found for {{travelmode.toLowerCase()}}</b-alert>

</section>
</template>

<script>
import { Location } from "@/scripts/Location"
export default {
  name: "DirectionItem",
  mixins: [],
  components: {},
  props: {
    "service": Object,
    "travelmode": String,
  },
  data() {
    return {
      directionsResult: null,
      isLoading: false
    }
  },
  watch: {
    'service.location': {
      immediate: true,
      handler( newVal, oldVal ) {
        if ( newVal != oldVal ) {
          this.directionsResult = null
          this.getDirections()
        }
      }
    },
    'store_search_address': {
      immediate: true,
      handler( newVal, oldVal ) {
        if ( newVal != oldVal ) {
          this.directionsResult = null
          this.getDirections()
        }
      }
    },
    'travelmode': {
      immediate: true,
      handler( newVal, oldVal ) {
        if ( newVal != oldVal ) {
          this.directionsResult = null
          this.getDirections()
        }
      }
    },
  },
  computed: {
    hasAny() {
      return this.route ? true : false
    },
    isReadyToGetDirections() {
      return this.service.location
    },
    needsDirections() {
      return this.directionsResult ? false : true
    },
    route() {
      return this.directionsResult && this.directionsResult.routes.length > 0 ? this.directionsResult.routes[ 0 ] : null
    },
    routeLeg() {
      return this.route && this.route.legs && this.route.legs.length > 0 ? this.route.legs[ 0 ] : false
    },
    routeDuration() {
      let text = this.routeLeg.duration.text
      if ( this.travelmode == "TRANSIT" ) {
        text += " by public transit"
      } else if ( this.travelmode == "DRIVING" ) {
        text += " by car"
      } else {
        text += " by foot"
      }

      return this.routeLeg ? text : "--"
    },
  },
  methods: {
    toggleDirections() {
      this.$store.dispatch( "updateTransitPref", this.travelmode )
    },
    getDirections() {
      if ( this.service.location && this.store_search_address ) {
        this.isLoading = true
        new Location( {
            destination: {
              lat: this.service.location.lat,
              lng: this.service.location.lng
            }
          } ).calcRoute( this.travelmode )
          .then( result => {
            this.directionsResult = result
            this.isLoading = false
          } ).catch( () => {
            this.isLoading = false
          } )
      }

    }
  }
};
</script>
<style scoped lang="scss">
.direction-item {
    @include module;
    background: theme-color-level(primary, -11.9);
    min-height: 10rem;

    .route {
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-gap: var(--p1);
        padding: var(--p1);
        align-items: center;
        color: inherit;
        padding: 1rem;

        .icon {
            color: inherit;
            height: 2rem;
            width: 2rem;
        }
    }

    ul.steps {

        li.step {
            font-size: 0.8rem;
            padding: 0.5rem 1rem;

        }
    }

}
</style>
