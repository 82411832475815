
<template>
<b-list-group-item class="no-results"
                   v-if="!hasResults && !isSearching">
  <p>
    We could not find any services within <b>{{within}}</b> of this location that match your criteria.
  </p>

  <p>
    For assistance finding resources call the Hunger Hotline at
    <b-link variant="outline-primary"
            :href="'tel:'+hotline.en">{{hotline.en}}</b-link>
    /
    <b-link variant="outline-primary"
            :href="'tel:'+hotline.es">{{hotline.es}}</b-link>
    or <a href="https://www.feedingamerica.org/find-your-local-foodbank">Find Your Local Food Bank</a>
  </p>

</b-list-group-item>
</template>
<script>
export default {
  name: "NoResults",
  computed: {
    within() {
      let c = this.$store.getters.current_radius_object
      return c ? c.text : null
    }
  }
};
</script>
<style scoped lang="scss">
.no-results {
    display: grid;
    grid-gap: 1rem;
    align-items: start;
    align-content: start;
}
</style>
