<template>
<Slice>
  <template v-slot:header>
    NavHeader
  </template>

  <div class="slice-headline">
    <b-container class="wrapper">
      <div class="headline">
        <h1>{{item.data.title}}</h1>
        <Icon type="MarkerLine" />
      </div>
    </b-container>
  </div>

</Slice>
</template>

<script>
export default {
  name: "SliceHeader",
  props: [ 'item' ],
  components: {},
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.slice-headline {
    padding: var(--p6) 0 var(--p2);

    .headline {
        .desktop & h1 {
            max-width: 70%;
        }

        .MarkerLine {
            height: auto;
            color: $danger;
        }

        .mobile & {
            h1 {
                line-height: 100%;
            }
            & .MarkerLine {
                width: 100%;
            }
        }

        .tablet & .MarkerLine {
            width: 70%;
        }

        .desktop & .MarkerLine {
            width: 50%;
        }
    }
}
</style>
