import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

// ######################################
// Bootstrap
import "@/styles/global.scss";
import {BootstrapVue} from "bootstrap-vue";
Vue.use(BootstrapVue, {
  breakpoints: ["xs", "sm", "md", "lg", "xl", "xxl", "xxxl"],
  BButton: {
    size: "sm",
    variant: "primary"
  }
});

// ######################################
// Markdown parser for SliceHeader
import VueSimpleMarkdown from "vue-simple-markdown";
Vue.use(VueSimpleMarkdown);

// ######################################
// Timespace for getting the timezone from a lat/long
import TimeSpace from "@mapbox/timespace";
Vue.use(TimeSpace);
Vue.prototype.$TimeSpace = TimeSpace;

// ######################################
// Prismic
import PrismicVue from "@prismicio/vue";
Vue.use(PrismicVue, {
  endpoint: "https://hfadb.prismic.io/api/v2"
});

// ######################################
// Vuex Store global getters
import {mapGetters} from "vuex";
Vue.mixin({
  computed: {
    ...mapGetters([
      "isPreloaded",
      "isSearching",
      "isReadyToSearch",
      "simple_address",
      "formatted_address",
      "current_radius",
      "domain",
      "hotline",
      "stories",
      "store_research_options",
      "store_search_address",
      "store_search_options",
      "store_search_results",
      "store_search_possible_options",
      "transportation_preference",
      "taxonomy_codes",
      "taxonomy_from_api",
      "locations",
      "services",
      "api_services",
      "service_primary_types",
      "service_secondary_types",
      "hasSearch",
      "hasAddress",
      "hasResults",
      "showSuggestService"
    ])
  }
});

// ######################################
// API
import {API} from "@/scripts/Api";
Vue.prototype.$API = API;

// ######################################
// SEARCH
import {SEARCH} from "@/scripts/Search";
Vue.prototype.$SEARCH = SEARCH;

// ######################################
// Global components
import Icon from "@/components/ui/Icon.vue";
import SuperButton from "@/components/ui/SuperButton.vue";
import Slice from "@/components/ui/Slice.vue";
import SliceHeader from "@/components/ui/SliceHeader.vue";
import SliceHeadline from "@/components/ui/SliceHeadline.vue";
import SliceVisual from "@/components/ui/SliceVisual.vue";
import SliceText from "@/components/ui/SliceText.vue";
import SliceAction from "@/components/ui/SliceAction.vue";
import SliceList from "@/components/ui/SliceList.vue";
import SliceTable from "@/components/ui/SliceTable.vue";
import SliceQuote from "@/components/ui/SliceQuote.vue";
import SliceEmbed from "@/components/ui/SliceEmbed.vue";
import NavItem from "@/components/ui/NavItem.vue";
import NavHeader from "@/components/ui/NavHeader.vue";
import NavFooter from "@/components/ui/NavFooter.vue";
import NotFound from "@/views/NotFound.vue";
Vue.component("Icon", Icon);
Vue.component("SuperButton", SuperButton);
Vue.component("Slice", Slice);
Vue.component("sliceheader", SliceHeader);
Vue.component("sliceheadline", SliceHeadline);
Vue.component("slicevisual", SliceVisual);
Vue.component("slicetext", SliceText);
Vue.component("sliceaction", SliceAction);
Vue.component("slicelist", SliceList);
Vue.component("slicecsv_table", SliceTable);
Vue.component("slicequote", SliceQuote);
Vue.component("sliceembed", SliceEmbed);
Vue.component("NavItem", NavItem);
Vue.component("NavHeader", NavHeader);
Vue.component("NavFooter", NavFooter);
Vue.component("NotFound", NotFound);

Vue.config.productionTip = false;
Vue.config.devtools = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
