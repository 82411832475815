<template>
<b-container class="sidebar">

  <b-link class="brand clean"
          to="/home">
    <Icon type="logo" />
  </b-link>


  <section>

    <div class="navigation">
      <NavItem v-for="link in nav"
               name="navitem"
               :key="link.id"
               :item="link"></NavItem>
    </div>
  </section>


</b-container>
</template>

<script>
export default {
  name: "Sidebar",
  components: {},
  data() {
    return {
      nav: []
    }
  },
  mounted() {
    this.getNavItems()
  },
  computed: {},
  methods: {
    getNavItems() {
      this.$prismic.client.getSingle(
          'navigation' )
        .then( ( document ) => {
          this.nav = document.data.sidebar_links.map( a => {
            let id = null
            if ( a.internal_link ) {
              id = a.internal_link
            } else if ( a.external_link && a.external_link.link_type == 'Document' ) {
              id = a.external_link.slug
            }
            let url = null
            let target = "_self"
            if ( a.external_link && a.external_link.link_type == 'Web' ) {
              url = a.external_link.url
              target = a.external_link.target
            }
            return {
              label: a.label,
              id: id,
              url: url,
              target: target
            }
          } )
        } )
    },
  }
};
</script>
<style scoped lang="scss">
.sidebar {
    position: sticky;
    top: 0;
    padding: 0;
    margin: 0;
    height: 100vh;
    padding: var(--p2);
    width: 100%;
    background: theme-color-level(primary, -12);
    box-shadow: 2rem 0 2rem rgba(0,0,0,0.1);
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-gap: 2rem;
    align-items: start;
    align-content: start;
    z-index: 9000;

    .brand {
        padding: 1rem;
        .icon {
            height: 4rem;
        }
    }

    section {
        display: grid;
        align-items: start;
        grid-gap: 1rem;
    }

    .heading {
        @include caps;
    }
    .navigation {}

    footer {
        align-self: end;
        font-size: 0.8rem;
    }

}
</style>
