
<template>
<b-container class="search"
             :class="{'empty': !hasResults && !hasAddress}">
  <header>
    <b-link to="/"
            aria-label="Home"
            @click="$store.dispatch( 'resetSearchAddress')">
      <div class="logos">
        <Icon type="Logo"></Icon>
        <Icon type="USDA"></Icon>
      </div>
    </b-link>
  </header>

  <section class="headline">
    <h1 class="display">Find food assistance anywhere</h1>
  </section>

  <section v-if="isPreloaded">
    <SearchAddress />
  </section>
  <b-spinner v-else
             type="grow"></b-spinner>

  <section class="middle"
           v-if="hasAddress && isPreloaded">

    <b-spinner variant="primary"
               class="ml-3"
               type="grow"
               v-if="isSearching"></b-spinner>

    <SuperButton v-show="hasResults && !isSearching && isReadyToSearch &&  hasAddress"
                 variant="danger"
                 :click="showResults">
      <Icon :type="services.length == 0 ? 'remove' : 'arrow' "></Icon>
      <p>{{buttonLabel}}</p>
    </SuperButton>


    <div class="no-results"
         v-if="!isSearching && !hasResults && hasAddress ">
      <h3 class="display">
        <b>No results found</b>
      </h3>
      <p>We could not find any services near this location that matches your criteria. For assistance finding resources call the Hunger Hotline at
        <b-link variant="outline-primary"
                :href="'tel:'+hotline.en">{{hotline.en}}</b-link>
        /
        <b-link variant="outline-primary"
                :href="'tel:'+hotline.es">{{hotline.es}}</b-link>
        or <a href="https://www.feedingamerica.org/find-your-local-foodbank">Find Your Local Food Bank</a>
      </p>
    </div>

  </section>

  <section v-if="hasAddress && isPreloaded">
    <SearchOptions />
  </section>

</b-container>
</template>
<script>
import SearchAddress from "@/components/db/search/SearchAddress"
import SearchOptions from "@/components/db/search/SearchOptions"
import breakpoints from "@/scripts/Breakpoints.js"

export default {
  name: "Search",
  components: {
    SearchAddress,
    SearchOptions
  },
  mixins: [ breakpoints ],
  computed: {
    buttonLabel() {
      let r = this.services.length
      if ( r == 1 ) {
        return `${r} result`
      } else if ( r == 0 ) {

        return 'No results found'
      } else {
        return `${r} results`
      }
    },
  },
  methods: {
    showResults() {
      let query = new URLSearchParams( this.$router.history.current.query ).toString();
      if ( this.isMobile ) {
        this.$router.push( `/results?${query}` )
      } else {
        this.$SEARCH.showBestMatch()
      }
    }
  }
};
</script>
<style scoped lang="scss">
.search {
    display: grid;
    align-items: start;
    align-content: start;
    padding-top: var(--pxxl);
    min-height: 70vh;

    > header,
    > section {
        transition: all 0.5s ease-in-out;
        margin: var(--pm) 0;
    }

    &.empty > header,
    &.empty > section {
        margin: var(--pxxl) 0;
    }

    header {
        display: grid;
        grid-gap: var(--p6);
        grid-template-rows: auto auto;

        .logos {
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: var(--p4);
            justify-content: start;

            .Logo {
                height: 4rem;
            }
            .USDA {
                height: 4rem;
                color: theme-color-level(primary,0);
            }

            .compact & {
                background: red;
            }

        }
    }

    section.headline {}

    section.middle {
        display: grid;
        align-content: center;
        align-items: center;
        justify-content: start;
        min-height: 6rem;

        .no-results {
            display: grid;
            grid-gap: var(--p4);
            max-width: 32rem;

            a {
                white-space: pre;
            }
        }
    }

}
</style>
