<template>
<Slice>
  <b-container fluid
               class="slice-text">
    <b-container class="wrapper">
      <TextView :text="slice.primary.text" />
    </b-container>
  </b-container>
</Slice>
</template>

<script>
import TextView from "@/components/ui/TextView"
export default {
  name: "SliceText",
  props: [ 'item', 'slice' ],
  components: { TextView }
};
</script>
<style scoped lang="scss">
.slice-text {
    .wrapper {
        display: grid;
        grid-row-gap: var(--p3);
    }
}
</style>
