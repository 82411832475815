
<template>
<div class="map">
  <div class="map-actual"
       v-show="!hideMap && hasLocation"
       :id="mapId" />
</div>
</template>
<script>
import { Location } from "@/scripts/Location"
export default {
  name: "Map",
  mixins: [],
  data() {
    return {}
  },
  props: {
    'item': Object,
    'preview': {
      type: Boolean,
      default: false,
    },
    'hideMap': {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    location: {
      immediate: true,
      deep: true,
      handler( newVal, oldVal ) {
        if ( newVal && newVal != oldVal ) {
          this.initMap()
        }
      }
    },
    transportation_preference( newVal, oldVal ) {
      if ( newVal != oldVal ) {
        this.initMap()
      }
    },
    store_search_address( newVal, oldVal ) {
      if ( newVal != oldVal ) {
        this.initMap()
      }
    }
  },
  computed: {
    location() {
      return this.item.location
    },
    hasLocation() {
      return this.location && this.location.lat ? true : false
    },
    origin() {
      if ( this.store_search_address ) {
        return {
          lat: this.store_search_address.geometry.location.lat(),
          lng: this.store_search_address.geometry.location.lng()
        }
      } else {
        return false
      }
    },
    mapId() {
      return "map" + this.item.id
    },
    mapElement() {
      return document.getElementById( this.mapId )
    },
  },
  methods: {
    initMap() {
      this.$nextTick( () => {
        if ( this.hasLocation ) {
          new Location( {
            mapElement: this.mapElement,
            preview: this.preview,
            origin: this.origin,
            destination: {
              lat: this.location.lat,
              lng: this.location.lng
            },
          } ).loadMap()
        }
      } )
    },
    // getLocation() {
    //   if ( navigator.geolocation ) {
    //     let options = {
    //       enableHighAccuracy: true,
    //       maximumAge: 2000
    //     }
    //     navigator.geolocation.getCurrentPosition( ( result ) => {
    //       this.position = result
    //       var marker = new this.google.maps.Marker( {
    //         position: { lat: result.location.coords.lat, lng: result.location.coords.lng },
    //         map: this.map,
    //         title: 'Click to zoom'
    //       } )
    //       this.map.panTo( marker.getPosition() );
    //     }, ( error ) => {
    //       console.warn( error )
    //     }, options );
    //   } else {
    //     this.position = "Geolocation is not supported by this browser.";
    //   }
    // },


  }
};
</script>
<style scoped lang="scss">
.map {
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;
    .map-actual {
        height: 100%;
        width: 100%;
    }

}
</style>
