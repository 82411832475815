<template>
<Slice>
  <b-container class="slice-quote">

    <b-container class="wrapper quote h4 display">
      <blockquote>
        <prismic-rich-text class="rich"
                           :field="slice.primary.quote" />
        <footer>
          <h6>
            <b-link v-if="slice.primary.attribution_link.url"
                    :href="slice.primary.attribution_link.url">&mdash; {{slice.primary.attribution}}</b-link>
            <span v-else-if="slice.primary.attribution">&mdash; {{slice.primary.attribution}}</span>
          </h6>
        </footer>
      </blockquote>


    </b-container>

  </b-container>
</Slice>
</template>

<script>
export default {
  name: "SliceTable",
  props: [ 'item', 'slice' ],
  components: {},
  computed: {

  },
  methods: {

  }
};
</script>
<style scoped lang="scss">
.slice-quote {

    .quote {
        // border-left: 3px solid $danger;
        margin-left: var(--p2);
        padding: var(--p2) var(--p4);

        footer {
            @include smallcaps;
            margin-left: var(--p3);
        }
    }

}
</style>
