<template>
<section class="contact"
         v-if="hasAny">
  <header>
    <span>
      Contact
    </span>
  </header>

  <ul>
    <li v-for="field in allFields"
        :key="field.id">
      <div>
        <!-- <h6 class="smallcaps">{{field.label}}</h6> -->
        <template v-if="field.label == 'email'">
          <b-link :href="'mailto:'+field.value">{{field.value}}</b-link>
        </template>
        <template v-if="field.label == 'url'">
          <b-link class="value"
                  :href="field.value"
                  target="_blank">{{field.value}}</b-link>
        </template>
      </div>
    </li>
    <li v-for="phone in phones"
        :key="phone.id">
      <div>
        <!-- <h6 class="smallcaps">Phone</h6> -->
        <b-link class="value"
                :href="formatedTelephone(phone).link">{{formatedTelephone(phone).text}}</b-link>
      </div>
    </li>
  </ul>

</section>
</template>

<script>
export default {
  name: "Contact",
  components: {},
  props: [ "service" ],
  data() {
    return {
      fields: [
        'email',
        'url'
      ]
    }
  },
  computed: {
    hasAny() {
      return this.allFields.concat( this.phones ).length > 0
    },
    phones() {
      return this.service.phones
    },
    allFields() {
      let results = []
      for ( let field of this.fields ) {
        let value = this.service[ field ] || null
        if ( value ) {
          let obj = {
            label: field.replace( "_", " " ),
            value: value
          }
          results.push( obj )
        }
      }
      return results
    },
  },
  methods: {
    formatedTelephone( p ) {
      let link = "tel:"
      link += p.number;
      let text = p.number.replace( /(\d{1})(\d{3})(\d{3})(\d{3})/, "+$1 ($2)-$3-$4" );

      if ( p && p.extension ) {
        link += "," + p.extension
        text += " x" + p.extension
      }
      return {
        'link': link,
        'text': text
      }
    }
  }
};
</script>
<style scoped lang="scss">
.contact {
    @include module;

    li {
        display: grid;
        grid-gap: 1rem;
        .icon {
            height: 1rem;
            color: theme-color-level(warning,2);
        }

    }

    &.print {
        * {
            color: $primary;
            font-size: 0.7rem;
        }
        header {
            display: none;
        }
        li {
            padding: 0;
            grid-gap: 0;
            grid-template-columns: 1fr;
            border: none;
            h6 {
                display: none;
            }
            .value {
                display: inline;
                word-wrap: break-all;
                word-break: break-word;

                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            }
        }
    }

}
</style>
