<template>
<section class="languages module"
         v-if="hasAny">
  <header>
    <span>
      Languages
    </span>
  </header>

  <ul>
    <li v-for="entry in service.languages"
        :key="entry.id">
      <div>
        {{readable(entry.language)}}
      </div>
    </li>
  </ul>

</section>
</template>

<script>
import ISO6391 from 'iso-639-1';
export default {
  name: "Languages",
  components: {},
  props: [ "service" ],
  data() {
    return {}
  },
  computed: {
    hasAny() {
      return this.service.languages.length > 0
    },
  },
  methods: {
    readable( data ) {
      let codes = data.split( ";" )
      let result = []
      for ( let code of codes ) {
        let real = ISO6391.getName( code )
        result.push( " " + real )
      }
      return result.toString()
    }
  }
};
</script>
<style scoped lang="scss">
.languages {
    @include module;
    li {
        display: grid;
        grid-gap: 1rem;

    }

}
</style>
