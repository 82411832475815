import {API} from "@/scripts/Api";
import Store from "@/store";
import Router from "@/router";
import _ from "lodash";

export class Search {
  performSearch(showBestMatch = false) {
    if (Store.getters.isReadyToSearch) {
      API.getAllServices().then(() => {
        if (showBestMatch) {
          this.showBestMatch();
        }
      });
    }
  }

  generateURLQuery() {
    let primary = Store.getters.store_search_options.primary_types;
    let secondary = Store.getters.store_search_options.secondary_types;
    let address = Store.getters.formatted_address;
    let radius = Store.getters.current_radius;
    let transportation_preference = Store.getters.transportation_preference;

    let query = {};

    let p = primary ? primary.toString() : null;
    if (p) {
      query.p = p;
    }
    let s = secondary ? secondary.toString() : null;
    if (s) {
      query.s = s;
    }
    let a = address ? address : null;
    if (a) {
      query.a = a;
    }
    let r = radius ? radius : null;
    if (r) {
      query.r = r;
    }

    let t = transportation_preference ? transportation_preference : null;
    if (t) {
      query.t = t;
    }
    let current = Router.history.current;
    let oldQuery = current.path.query;
    let routeName = current.name;
    if (
      !_.isEqual(oldQuery, query) &&
      ["Home", "Results", "Result"].includes(routeName)
    ) {
      // console.warn("SEARCH", "generateURLQuery");
      Router.replace({path: current.path, query: query}).catch(() => {});
    }
  }

  showBestMatch(format) {
    let best_match = Store.getters.services[0];
    if (best_match) {
      let query = new URLSearchParams(Router.history.current.query).toString();
      if (format == "mobile") {
        Router.push("/results/?" + query).catch(() => {});
      } else {
        Router.push("/result/" + best_match.id + "/?" + query).catch(() => {});
      }
    } else {
      console.warn("No best match");
      Store.dispatch("updateIsSearching", false);
    }
  }
}

export const SEARCH = new Search();
