<template>
<Slice fluid>
  <div class="slice-header"
       :class="[tone]">
    <b-container class="wrapper">
      <div class="slice-header-card"
           v-if="hasActions || hasHeadline">
        <div class="side">
          <div class="headline">
            <h1>{{slice.primary.headline}}</h1>
            <Icon type="MarkerLine"></Icon>
          </div>
          <h4 class="description display"
              v-if="hasSubheadline">
            {{slice.primary.subheadline}}
          </h4>
          <div class="actions"
               v-if="hasActions">
            <SuperButton v-for="item in actions"
                         variant="primary"
                         :href="actionHref(item)"
                         :key="item.id">
              <Icon type="Arrow"></Icon>
              <p>{{item.call_to_action}}</p>
            </SuperButton>
          </div>
        </div>
      </div>
    </b-container>
    <Icon type="Brush"></Icon>
    <Icon type="BrushTop"></Icon>
  </div>
</Slice>
</template>

<script>
export default {
  name: "SliceHeader",
  props: [ 'item', 'slice' ],
  components: {},
  computed: {
    hasActions() {
      return this.actions.length > 0
    },
    hasHeadline() {
      return this.slice.primary.headline ? true : false
    },
    hasSubheadline() {
      return this.slice.primary.subheadline ? true : false
    },
    actions() {
      return this.slice.items.filter( i => i.call_to_action !== null )
    },
    tone() {
      return this.slice.primary.tone
    },
  },
  methods: {
    actionHref( actionItem ) {
      if ( actionItem.link.link_type == 'Document' ) {
        return actionItem.link.uid
      } else {
        return actionItem.link.url
      }
    }
  }
};
</script>
<style scoped lang="scss">
.slice-header {
    display: grid;
    background: theme-color-level(primary,-10);
    grid-template-rows: 1fr;
    align-items: center;
    overflow: hidden;

    &.urgent {
        background: linear-gradient(0deg, theme-color-level(danger, -3) 0%, theme-color-level(danger, 2) 100%);
    }

    &.normal {
        background: linear-gradient(0deg, theme-color-level(primary, -3) 0%, theme-color-level(primary, 2) 100%);
    }

    .desktop &,
    .tablet & {
        min-height: 50vh;
    }

    .mobile & {
        padding: 0;

        .wrapper {
            padding: 0;
        }
    }

    &.important {
        background-color: theme-color-level(secondary,-2);
    }

    .Brush {
        position: absolute;
        bottom: 0;
    }
    .BrushTop {
        position: absolute;
        top: 0;
    }

    .MarkerLine {
        color: white;
    }

    .slice-header-card {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: "text";
        border-radius: 0.5rem 0.5rem 0 0;
        overflow: hidden;
        align-items: center;
        align-self: center;
        justify-self: center;
        z-index: 100;

        .side {
            grid-area: text;
            display: grid;
            align-content: center;
            justify-content: start;
            grid-gap: 1rem;

            .mobile & {
                grid-gap: 3rem;
                padding: 4rem;

                .MarkerLine {
                    width: 80%;
                }
                .actions {
                    justify-self: start;
                }

            }
            .tablet & {
                grid-gap: 3rem;
                padding: var(--p3);
                .actions {
                    justify-self: start;
                }
            }

            .desktop & {
                grid-template-columns: 1fr 1fr;
                .headline {
                    grid-column: 1 /span 2;
                }
                .description {
                    grid-column: 1;
                }
                .actions {
                    grid-column: 2;
                    justify-self: end;
                }
            }

            .MarkerLine {
                grid-area: line;
                height: auto;
                width: 60%;
            }

            .headline h1 {
                color: white;
            }

            .actions {
                display: grid;
                grid-gap: var(--p1);
                justify-self: end;
                align-content: center;
                align-items: center;
            }

            .description {
                color: white;
            }
        }

        .desktop &,
        .tablet & {}

        .mobile & {
            width: 100%;
            border-radius: 0;
            grid-gap: 0;
        }

    }
}
</style>
