<template>
<div class="name module">
  <h2 class="display">{{service.name}}</h2>
  <span>{{service.alternative_name}}</span>
  <div class="more">
    <slot> </slot>
  </div>
</div>
</template>

<script>
export default {
  name: "Name",
  components: {},
  props: [ 'service' ],
  data() {
    return {}
  },
  computed: {
    service_name() {
      return this.service.alternative_name || this.service.name
    }
  }
};
</script>
<style scoped lang="scss">
.name {
    display: grid;
    grid-gap: 0;

    .more {
        display: grid;
        grid-gap: 0.5rem;
    }

    * {
        color: white;
    }

    &.print {
        font-size: 0.7rem;
        grid-gap: 0;

        .more {
            grid-gap: 0;
        }

        .display {
            font-size: 1rem;
            font-weight: bold;
        }
    }

}
</style>
