<template>
<div class="page"
     v-if="item">

  <component v-for="slice in body"
             :key="slice.id"
             :is="'slice'+slice.slice_type"
             :item="item"
             :slice="slice">
  </component>

</div>
</template>

<script>
export default {
  name: "Page",
  props: [ 'uid' ],
  computed: {
    item() {
      return this.stories.find( story => story.uid == this.uid )
    },
    needsHeader() {
      return this.item.data.body[ 0 ].slice_type == 'header' ? false : true
    },
    body() {
      if ( this.needsHeader ) {
        return [ { slice_type: "headline", tone: 'normal' }, ...this.item.data.body ]
      }
      return this.item.data.body
    }
  }
};
</script>
<style scoped lang="scss">
.page {
    display: grid;
    grid-gap: 5rem;
}
</style>
