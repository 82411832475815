import axios from "axios";
import Store from "@/store";

const API_URL = process.env.VUE_APP_API_URL;
const Axios = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {key: process.env.VUE_APP_API_KEY}
});
const GoogleForm = axios.create({
  baseURL:
    "https://script.google.com/macros/s/AKfycbyfqeqQ2B2HVJ1ulXldH8viGtATYOGCaLWtM22liwVlnRKrDq0/exec/",
  timeout: 10000
});

export class HFAAPI {
  constructor() {}

  async initialize() {
    await this.fetchTaxonomy();
    Store.dispatch("updateIsPreloaded", true);
  }
  /*
  Attempt to get a complete Service object for the Service page.
  If we have just landed on this screen with no prior API fetching
  we will pre-load Taxonomy, Locations, and the SPECIFIC service.
  We then combine everything into the Service object and return it.
  If we have already fetched this data from the API and it exists in
  the store, then return from the store.
  */
  getSpecificService(id) {
    // console.log("API", "getSpecificService", id);
    let existing = this.findService(id);
    if (existing) {
      return Promise.resolve(existing);
    }

    return new Promise((resolve, reject) => {
      this.fetchTaxonomy()
        .then(() => this.fetchService(id))
        .then(result => resolve(result))
        .catch(error => {
          console.error("API", "getSpecificService failed:", error);
          reject(error);
        });
    });
  }

  /*
  Query the API for all all Services that meet the search criteria
  (geolocation, and taxonomy filters). We only perform this Query
  if we have an address to search from.
  */
  async getAllServices() {
    if (!Store.getters.store_search_address || Store.getters.isSearching) {
      return;
    }

    await this.initialize();

    // console.log("API", "getAllServices");
    /*
    All results MUST be set to ACTIVE
    */
    let params = "?status=Active";

    /*
    Regular filters are all filters that can be simply placed into
    the taxonomy parameter. ALL of these are treated as OR by the API.
    */
    let regular_OR_filters = Store.getters.active_regular_OR_search_filters.map(
      a => a.id
    );
    if (regular_OR_filters.length > 0) {
      params +=
        "&service_taxonomy.taxonomy_id=" + regular_OR_filters.toString();
    }

    /*
    Regular filters are all filters that can be simply placed into
    the taxonomy parameter. ALL of these are treated as OR by the API.
    */
    let regular_AND_filters = Store.getters.active_regular_AND_search_filters.map(
      a => a.id
    );
    for (let f of regular_AND_filters) {
      params += "&service_taxonomy.taxonomy_id=" + f;
    }

    /*
    Special filters are filters that need to be processed locally before
    sending to server. They all are marked as `special` and are handled here
    and add the appropriate parameters to the query.
    */
    let special_filters = Store.getters.active_special_search_filters;
    if (special_filters.find(a => a.code == "ES")) {
      params += "&languages.language=es";
    }
    if (special_filters.find(a => a.code == "SR")) {
      params += "&eligibility.seniors_only=1";
    }
    if (special_filters.find(a => a.code == "JR")) {
      params += "&eligibility.x18_years_old_and_under=1";
    }

    /*
    Location creates a boundary around the search address and sends the
    four geopoints for the API to ONLY include results that fall
    inside this boundary.
    */

    let search_bounds = Store.getters.bounds;

    params +=
      "&service_at_location.latitude=equallesserthan_" +
      search_bounds.topLeft.lat;
    params +=
      "&service_at_location.latitude=equalgreaterthan_" +
      search_bounds.bottomRight.lat;
    params +=
      "&service_at_location.longitude=equalgreaterthan_" +
      search_bounds.topLeft.lng;
    params +=
      "&service_at_location.longitude=equallesserthan_" +
      search_bounds.bottomRight.lng;

    Store.dispatch("updateIsSearching", true);
    // console.warn("PARAMS", params);

    let results = [];
    let page = 0;
    let hasMorePages = true;
    do {
      let response = await Axios.get("/services/complete" + params, {
        params: {status: "Active", page: ++page}
      }).catch(error => {
        Store.dispatch("updateIsSearching", false);
        console.error(error.message);
        return Promise.reject(error);
      });
      hasMorePages = response.status == 200;
      // console.warn("API CODE", response.status);
      if (hasMorePages) {
        results = results.concat(response.data);
      }
    } while (hasMorePages);
    Store.dispatch("updateIsSearching", false);
    Store.dispatch("updateAPIServices", results);
    return Promise.resolve(results);
  }

  findService(id) {
    if (id) {
      // console.log("API", "findService", id);
      return Store.getters.services.find(a => a.id == id);
    }
  }

  /*
  Query the API for all Taxonomy entries (if needed). Since the front-end
  only cares about some of these fields for filtering purposes, we inject
  the API Taxonomy ID into our local store.taxonomy_codes.
  */
  fetchTaxonomy() {
    if (Store.getters.taxonomy_from_api) {
      Store.dispatch("initialize");
      return Promise.resolve();
    }

    // console.log("API", "fetchTaxonomy");
    return Axios.get("/taxonomy")
      .then(response => {
        if (response.status == 200) {
          let results = response.data;
          Store.dispatch("updateTaxonomyFromAPI", results);
          // Convert our local Object of the definitions we care about
          // into an iterable array and go through each entry
          let codes = Store.getters.taxonomy_codes.slice().map(entry => {
            let code = entry.code;
            // Look for a match from the API. We take the entry code
            // for example "BD-1800.2000" and see if it is included
            // in any of the API results.
            let match = results
              .filter(a => a.name)
              .find(a => a.name.split(" ")[0] === code);
            // If we found a match, then update the store by injecting
            // the API taxonomy id into our local Object.
            if (match) {
              entry.id = match.id;
            }
            return entry;
          });
          Store.dispatch("updateTaxonomyCodes", codes);
          Store.dispatch("initialize");

          return Promise.resolve(results);
        } else {
          // We got a response from the server, but it was not OK
          console.error(response.statusText);
          return Promise.reject(response.statusText);
        }
      })
      .catch(error => {
        // We did not get a response from the server, for whatever reason
        console.error(error.message);
        return Promise.reject(error);
      });
  }

  /*
  Query the API for all Location (address) entries (if needed) and save
  them locally to store.locations
  */

  async fetchAllLocations() {
    if (Store.getters.locations) {
      // console.warn("API", "Locations already exist");
      return Promise.resolve();
    }

    let results = [];
    let page = 0;
    let hasMorePages = true;

    do {
      let response = await Axios.get("/locations/complete", {
        params: {page: ++page}
      });
      hasMorePages = response.status == 200;
      results = results.concat(response.data);
    } while (hasMorePages);
    Store.dispatch("updateLocations", results);
    return Promise.resolve();
  }

  fetchSpecificLocation(id) {
    return Axios.get("/locations/complete/" + id)
      .then(response => {
        if (response.status == 200) {
          return Promise.resolve(response.data);
        } else {
          // We got a response from the server, but it was not OK
          console.error(response.statusText);
          return Promise.reject(response.statusText);
        }
      })
      .catch(error => {
        console.error(error.message);
        return Promise.reject(error);
      });
  }

  /*
  Query the API for a single Service and add it to the store.
  */

  fetchService(id) {
    // console.log("API", "fetchService", id);
    let params = {
      id: id,
      status: "Active"
    };
    return Axios.get("/services/complete/", {params})
      .then(response => {
        if (response.status == 200 && response.data.length > 0) {
          Store.dispatch("addToAPIServices", response.data);
          return Promise.resolve();
        } else {
          return Promise.reject("Service not found");
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /*
  Query the API for all services for research
  */

  async fetchAllServicesForResearch() {
    console.log("API", "fetchAllServicesForResearch");

    let params = "?status=Active";

    /*
    Regular filters are all filters that can be simply placed into
    the taxonomy parameter. ALL of these are treated as OR by the API.
    */
    let regular_OR_filters = Store.getters.active_regular_OR_research_filters.map(
      a => a.id
    );
    if (regular_OR_filters.length > 0) {
      params +=
        "&service_taxonomy.taxonomy_id=" + regular_OR_filters.toString();
    }

    /*
    Regular filters are all filters that can be simply placed into
    the taxonomy parameter. ALL of these are treated as OR by the API.
    */
    let regular_AND_filters = Store.getters.active_regular_AND_research_filters.map(
      a => a.id
    );
    for (let f of regular_AND_filters) {
      params += "&service_taxonomy.taxonomy_id=" + f;
    }

    /*
    Special filters are filters that need to be processed locally before
    sending to server. They all are marked as `special` and are handled here
    and add the appropriate parameters to the query.
    */
    let special_filters = Store.getters.active_special_research_filters;
    if (special_filters.find(a => a.code == "ES")) {
      params += "&languages.language=es";
    }
    if (special_filters.find(a => a.code == "SR")) {
      params += "&eligibility.seniors_only=1";
    }
    if (special_filters.find(a => a.code == "JR")) {
      params += "&eligibility.x18_years_old_and_under=1";
    }

    let results = [];
    let page = 0;
    let hasMorePages = true;
    console.warn("API QUERY", params);
    do {
      let response = await Axios.get("/services/complete" + params, {
        params: {status: "Active", page: ++page}
      });
      hasMorePages = response.status == 200;
      console.warn("API CODE", response.status);
      results = results.concat(response.data);
    } while (hasMorePages);

    Store.dispatch("updateAPIServices", results);
    return Promise.resolve(results);
  }

  /*
  Query the API for a specific organization
  */

  fetchOrganization(id) {
    // console.log("API", "fetchOrganization", id);
    return Axios.get(`/organizations/${id}`)
      .then(response => {
        if (response.status == 200) {
          return response.data;
        } else {
          // We got a response from the server, but it was not OK
          return Promise.reject(response.statusText);
        }
      })
      .catch(error => {
        // We did not get a response from the server, for whatever reason
        console.error(error.message);
        return Promise.reject(error);
      });
  }

  sendErrorToGoogle(url_parameters) {
    return GoogleForm.get(
      "https://script.google.com/macros/s/AKfycbyfqeqQ2B2HVJ1ulXldH8viGtATYOGCaLWtM22liwVlnRKrDq0/exec",
      url_parameters
    )
      .then(response => {
        console.log(response);
        if (response.status == 200) {
          return response.data;
        } else {
          // We got a response from the server, but it was not OK
          return Promise.reject(response.statusText);
        }
      })
      .catch(error => {
        // We did not get a response from the server, for whatever reason
        console.error(error.message);
        return Promise.reject(error);
      });
  }

  /*
  Query the API for a all organizations
  */

  fetchAllOrganizations() {
    // console.log("API", "fetchAllOrganizations");
    return Axios.get(`/organizations/complete`)
      .then(response => {
        if (response.status == 200) {
          return response.data;
        } else {
          // We got a response from the server, but it was not OK
          return Promise.reject(response.statusText);
        }
      })
      .catch(error => {
        // We did not get a response from the server, for whatever reason
        console.error(error.message);
        return Promise.reject(error);
      });
  }
}

export let API = new HFAAPI();
