
<template>
<div class="pdf">
  <b-button variant="outline-primary"
            @click="downloadPDF"
            aria-label="Print">
    Print
  </b-button>

  <div class="pdf_page"
       ref="page">

    <content>
      <div class="result"
           v-for="service in services.slice( 0, 5 )"
           :key="service.id">

        <div class="top">
          <Name class="print"
                :service="service" />

          <Address class="print"
                   :location="service.location" />

          <Taxonomy class="print"
                    :service="service" />

          <Contact class="print"
                   :service="service" />
        </div>

        <div class="bottom">

          <Calendar class="print"
                    type="regular"
                    :service="service" />

          <Calendar class="print"
                    type="holiday"
                    :service="service" />
        </div>
      </div>
    </content>

    <footer>

      <div class="logos">
        <img class="logo"
             src="@/assets/Logo-bw.png" />
        <img class="USDA"
             src="@/assets/USDA-bw.png" />
      </div>

      <div class="more">
        For more locations and information visit <b>{{domain.simple}}</b><br />
        or call the Hunger Hotline <b>{{hotline.en}}</b> or <b>{{hotline.es}}</b>
      </div>

      <div class="notice">
        Please call sites before visiting to confirm hours and requirements
      </div>

    </footer>

  </div>

</div>
</template>
<script>
import Name from "@/components/db/service/Name"
import Address from "@/components/db/service/Address"
import Taxonomy from "@/components/db/service/Taxonomy"
import Calendar from "@/components/db/service/Calendar"
import Contact from "@/components/db/service/Contact"
import PDFMixin from "@/scripts/PDF.js"
export default {
  name: "PDF",
  mixins: [ PDFMixin ],
  components: {
    Name,
    Address,
    Taxonomy,
    Contact,
    Calendar
  },
  data() {
    return {}
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.pdf {

    .pdf_page {
        // FOR PREVIEWING
        // height: 8.5in;
        // width: 11in;
        // background: white;
        // z-index: 10000;
        // @include shadow-lg;

        display: none;
    }
}
.pdf_page {
    padding: 3%;
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 1fr auto;
    align-content: space-between;
    align-items: start;
    height: 8in;

    > * {
        color: black !important;
        border-color: black !important;
    }

    footer {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: auto auto 1fr;
        align-items: center;
        align-content: center;
        padding: 0;
        line-height: 100%;

        .logos {
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
            align-content: center;
            grid-gap: 1rem;

            .USDA,
            .logo {
                height: 2rem;
            }
        }

        .more {
            font-size: 0.7rem;
        }

        .notice {
            padding: 0.2rem;
            font-size: 0.8rem;
            font-weight: bold;
            border: 0.5mm solid;
            text-align: center;
        }

    }

    content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(9rem , 1fr));
        height: 100%;

        .result {
            border-right: solid 0.5mm theme-color-level(primary,2);
            padding: 0.3cm;
            display: grid;
            grid-template-rows: minmax(10rem, auto) 1fr;
            break-inside: avoid;
            grid-gap: 0.5rem;
            align-items: start;
            align-content: start;
            justify-content: start;

            > .module header {
                font-size: 0.6rem;
            }

            .bottom,
            .top {
                display: grid;
                grid-gap: 0.2rem;
            }

            &:last-of-type {
                border-right: none;
            }
        }
    }
}
</style>
