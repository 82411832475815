<template>
<div class="not-found">

  <h1 class="display">
    <slot name="headline">
      We could not find the page you requested
    </slot>
  </h1>
  <Icon type="MarkerLine" />
  <p>
    <slot></slot>
  </p>
  <SuperButton class="my-3"
               variant="primary"
               to="/">
    <p>Find food</p>
    <Icon type='arrow' />
  </SuperButton>
</div>
</template>

<script>
export default {
  name: "NotFound",
  computed: {}
};
</script>
<style scoped lang="scss">
.not-found {
    background: $danger;
    display: grid;
    align-items: center;
    align-content: center;
    justify-content: center;

    .warning {
        height: 6rem;
    }

    .MarkerLine {
        height: 3rem;
        color: pink;
    }

    .icon,
    h1,
    p {
        color: white;
        color: white;
    }
}
</style>
