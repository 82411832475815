export default {
  created() {
    this.getBreakpoint();
    window.addEventListener("resize", this.getBreakpoint);
  },
  data() {
    return {
      bootstrap: null
    };
  },
  computed: {
    isMobile() {
      return this.bootstrap.format == "mobile";
    }
  },
  methods: {
    getBreakpoint() {
      let breakpoints = [
        {size: "xs", format: "mobile", pixels: 0},
        {size: "sm", format: "mobile", pixels: 425},
        {size: "md", format: "tablet", pixels: 768},
        {size: "lg", format: "tablet", pixels: 1024},
        {size: "xl", format: "desktop", pixels: 1400},
        {size: "xxl", format: "desktop", pixels: 1600},
        {size: "xxxl", format: "desktop", pixels: 1900}
      ];
      let width = window.innerWidth;
      for (let breakpoint of breakpoints) {
        if (width >= breakpoint.pixels) {
          this.bootstrap = breakpoint;
        }
      }
    }
  }
};
