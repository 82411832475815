<template>
<Slice>

  <div class="slice-embed"
       ref="embed"
       :style="{'height': adjustedHeight+'px'}">
    <prismic-embed :field="slice.primary.url" />
  </div>
</Slice>
</template>

<script>
export default {
  name: "SliceEmbed",
  props: [ 'item', 'slice' ],
  components: {},
  data() {
    return {
      height: 0,
      width: 0
    }
  },
  mounted() {
    this.getWidth()
  },
  created() {
    window.addEventListener( "resize", this.getWidth );
  },
  destroyed() {
    window.removeEventListener( "resize", this.getWidth );
  },
  computed: {
    adjustedHeight() {
      return this.width > 0 ? this.width * 0.56 : 100;
    }
  },
  methods: {
    getWidth() {
      this.width = this.$refs.embed.clientWidth
    }
  }
};
</script>
<style scoped lang="scss">
.slice-embed {
    display: grid;
    border-radius: 1rem;
    overflow: hidden;
    @include shadow-xl;
    width: 100%;
    height: 100%;

    > iframe {
        display: block;
        height: auto;
        width: 100%;
    }

    .mobile & {
        border-radius: 0;
    }
}
</style>
