
<template>
<div class="transportation-toggle">


  <b-button-group>
    <b-button @click="choose('DRIVING')"
              :class="{'active': pref=='DRIVING'}"
              :variant="pref=='DRIVING'?'primary':'outline-primary'">
      <!-- <Icon type="Driving"></Icon>  -->
      Driving
    </b-button>
    <b-button @click="choose('TRANSIT')"
              :class="{'active': pref=='TRANSIT'}"
              :variant="pref=='TRANSIT'?'primary':'outline-primary'">
      <!-- <Icon type="Transit"></Icon>  -->
      Transit
    </b-button>
    <b-button @click="choose('WALKING')"
              :class="{'active': pref=='WALKING'}"
              :variant="pref=='WALKING'?'primary':'outline-primary'">
      <!-- <Icon type="Walking"></Icon>  -->
      Walking
    </b-button>
  </b-button-group>

</div>
</template>
<script>
export default {
  name: "TransportationToggle",
  data() {
    return {
      pref: null
    }
  },
  mounted() {},
  computed: {
    name() {
      return "transportation-toggle" + this._uid
    },
    tp() {
      return this.store_search_options.transportation_preference
    }
  },
  watch: {
    tp: {
      immediate: true,
      handler( newVal, oldVal ) {
        if ( newVal != oldVal ) {
          this.pref = newVal
        }
      }
    },
    pref: {
      handler( newVal, oldVal ) {
        if ( newVal != oldVal && newVal != this.store_search_options.transportation_preference ) {
          this.$store.dispatch( "updateTransitPref", newVal )
        }
      }
    }
  },
  methods: {
    choose( type ) {
      this.pref = type

      this.$gtag.event( "toggle_transportation_preference", {
        event_category: "directions",
        event_label: type,
        value: 1
      } );
    }
  }
};
</script>
<style scoped lang="scss">
.transportation-toggle {
  > * {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    align-content: center;
    min-width: 8rem;

    > * {
      justify-content: center; // Adjusted to use direct descendant selector
    }
  }

  .icon {
    height: 1rem;
    width: 1rem;
  }

  .active {
    background: red;
  }
}

</style>
