<template>
<div class="organization"
     v-if="!error">
  <b-spinner variant="white"
             type="grow"
             v-if="!name" />
  <span v-else
        class="org">{{name}}</span>
</div>
</template>

<script>
import { API } from '@/scripts/Api.js';
export default {
  name: "Organization",
  components: {},
  props: [ 'organizationId' ],
  data() {
    return {
      organization: {},
      error: null,
    }
  },
  watch: {
    organizationId: {
      immediate: true,
      handler( newVal, oldVal ) {
        if ( newVal != null && newVal != oldVal ) {
          API.fetchOrganization( newVal )
            .then( result => this.organization = result[ 0 ] )
            .catch( error => this.error = error )
        }
      }
    }
  },
  computed: {
    name() {
      return this.organization.name || null
    }
  }
};
</script>
<style scoped lang="scss">
.organization {
    @include module;
    span {
        color: white;
    }

    .spinner-grow {
        height: 0.5rem;
        width: 0.5rem;
    }

    &.print {
        font-size: 0.75rem;
    }
}
</style>
