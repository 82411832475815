<template>
<b-container id="app"
             :class="[$data.bootstrap.size, $data.bootstrap.format]"
             fluid>

  <b-sidebar id="sidebar-1"
             no-header
             shadow
             backdrop>
    <Sidebar id="sidebar-1" />
  </b-sidebar>

  <b-row no-gutters
         class="wrapper">

    <b-col v-if="showSidebar"
           lg="3"
           xl="3"
           xxl="3"
           xxxl="2"
           class="side d-none d-lg-block">
      <component :is="showSidebar" />
    </b-col>


    <b-col class="main">
      <NavHeader nosearch
                 v-if="showNavbar" />
      <router-view />
      <NavFooter v-if="showFooter" />
    </b-col>
  </b-row>
  <!-- <div id="size">
    {{$data.bootstrap.format}}
  </div> -->

</b-container>
</template>
<script>
import _ from "lodash"
import breakpoints from "@/scripts/Breakpoints.js"
import Mixins from "@/scripts/Mixins.js"
import Sidebar from "@/components/db/Sidebar.vue"
import SearchResults from "@/components/db/search/SearchResults.vue"
export default {
  name: "App",
  components: {
    Sidebar,
    SearchResults
  },
  mixins: [ breakpoints, Mixins ],
  data() {
    return {
      showSidebar: false,
      showFooter: true,
      showNavbar: false,
      story: {
        content: {
          body: []
        }
      }
    }
  },
  created() {
    this.$API.initialize()
    this.getContent()
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.showSidebar = this.$route.meta.sidebar
        this.showFooter = this.$route.meta.footer
        this.showNavbar = this.$route.meta.navbar
      }
    },
    '$route.query': {
      immediate: true,
      handler( params, oldParams ) {
        if ( !_.isEqual( params, oldParams ) ) {
          // Only trigger on certain routes
          if ( [ "Home", "Results", "Result" ].includes( this.$route.name ) ) {
            if ( this.$route.name == "Home" ) {
              this.$SEARCH.performSearch()
            } else if ( !oldParams || params && params.a != oldParams.a ) {
              this.$SEARCH.performSearch( true )
            } else {
              this.$SEARCH.performSearch()
            }
          }

        }
      }
    }
  },
  mounted() {
    this.showSidebar = this.$route.meta.sidebar
    this.showFooter = this.$route.meta.footer
  },
  computed: {
    query() {
      return this.store_search_options
    },
    back() {
      let route = this.$route.name
      let query = new URLSearchParams( this.$router.history.current.query ).toString();
      if ( route == 'Service' ) {
        // Back to results
        return `/results?${query}`
      } else if ( route == 'Results' ) {
        // Back to search
        return `/?${query}`
      } else {
        return `/`
      }
    }
  },
  methods: {
    getContent() {
      this.$prismic.client.query( this.$prismic.Predicates.any( 'document.type', [ 'page' ] ), {
        orderings: '[my.blog_post.date desc]',
        fetchLinks: [ 'person.biography', 'person.portrait', 'person.full_name' ]
      } ).then( ( response ) => {
        this.$store.dispatch( "updateStories", response.results )
      } );
    },
  }
};
</script>
<style scoped lang="scss">
#app {
    height: 100vh;
    padding: 0;
    position: relative;

    .wrapper {
        padding: 0;
        position: relative;

        .main {
            display: grid;
            padding: 0;
        }

        .side {}
    }
}
</style>
