<!-- ////////////////////////////////////////////// -->
<!-- ////////////////  HTML //////////////////////// -->
<!-- ////////////////////////////////////////////// -->
<template>
<component class="icon"
           :class="type"
           :id="type  "
           ref="icon"
           :alt="alt"
           :is="type" />
</template>

<!-- ////////////////////////////////////////////// -->
<!-- ///////////////  JAVASCRIPT ///////////////////-->
<!-- ////////////////////////////////////////////// -->
<script>
import Add from '@/assets/Add.svg'
import Arrow from '@/assets/Arrow.svg'
import ArrowLeft from '@/assets/ArrowLeft.svg'
import Brush from '@/assets/Brush.svg'
import BrushTop from '@/assets/BrushTop.svg'
import Check from '@/assets/Check.svg'
import Driving from '@/assets/Driving.svg'
import Download from '@/assets/Download.svg'
import Good from '@/assets/Good.svg'
import Home from '@/assets/Home.svg'
import List from '@/assets/List.svg'
import Location from '@/assets/Location.svg'
import Logo from '@/assets/Logo.svg'
import LogoKO from '@/assets/Logo-KO.svg'
import MarkerLine from '@/assets/Line.svg'
import Menu from '@/assets/Menu.svg'
import Remove from '@/assets/Remove.svg'
import Settings from '@/assets/Settings.svg'
import Time from '@/assets/Time.svg'
import Transit from '@/assets/Transit.svg'
import USDA from '@/assets/USDA.svg'
import Walking from '@/assets/Walking.svg'
import Warning from '@/assets/Warning.svg'

export default {
  name: 'Icon',
  components: {
    Add,
    Arrow,
    ArrowLeft,
    Brush,
    BrushTop,
    Check,
    Driving,
    Download,
    Good,
    Home,
    List,
    Location,
    Logo,
    LogoKO,
    MarkerLine,
    Menu,
    Remove,
    Settings,
    Time,
    Transit,
    USDA,
    Walking,
    Warning,
  },
  props: {
    type: String,
  },
  computed: {
    alt() {
      return this.type
    }
  }
}
</script>

<!-- ////////////////////////////////////////////// -->
<!-- ////////////////  CSS /////////////////////////-->
<!-- ////////////////////////////////////////////// -->
<style scoped lang="scss">
.icon {
    height: inherit;
    width: inherit;
    color: inherit;

    * {
        color: inherit;
        shape-rendering: geometricPrecision;
    }
}
</style>
