<template>
<main class="result"
      :class="{'hasService':uid, 'isMobile':isMobile}">

  <content :class="{'isMobile':isMobile}">

    <SearchSidebar v-if="!isMobile"
                   :uid="uid"
                   @show-options="handleShowOptions" />

    <Service v-if="!showOptions"
             :uid="uid"></Service>

    <div v-else
         class="empty">
    </div>

  </content>

</main>
</template>
<script>
import SearchSidebar from "@/components/db/search/SearchSidebar"
import Service from "@/components/db/Service"
import breakpoints from "@/scripts/Breakpoints"
export default {
  name: "Result",
  components: {
    SearchSidebar,
    Service,
  },
  props: [ 'uid' ],
  mixins: [ breakpoints ],
  data() {
    return {
      showOptions: false
    }
  },
  computed: {},
  methods: {
    handleShowOptions( val ) {
      this.showOptions = val
    }
  }
};
</script>
<style scoped lang="scss">
.result {
    display: grid;
    grid-template-areas: "nav" "content";
    grid-template-rows: auto 1fr;
    height: 100vh;
    position: relative;

    content {
        grid-area: content;
        display: grid;
        overflow-y: hidden;
        grid-template-columns: 1fr 3fr;

        &.isMobile {
            grid-template-columns: 1fr;
        }

        .empty {
            background: theme-color-level(primary,-11);
            display: grid;
            align-items: center;
            justify-items: center;
            font-size: 1.2rem;
            color: theme-color-level(primary,-6);
        }
    }

}
</style>
