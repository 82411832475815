
<template>
<b-list-group class="search-results"
              tag="ul"
              v-if="!isSearching">
  <b-list-group-item class="large">
    <span>For help finding additional resources, please contact the Hunger Hotline at: <a href="tel:+18663486479">1-866-3-HUNGRY</a> or <a href="https://www.feedingamerica.org/find-your-local-foodbank">Find Your Local Food Bank</a></span>
  </b-list-group-item>
  <ResultItem v-for="result in uniqueResults"
              :service="result"
              :key="result.id"></ResultItem>


  <b-list-group-item class="simple"
                     v-if="showSuggestService"
                     to="/suggest-service">
    <span>Suggest a service</span>
  </b-list-group-item>

  <NoResults class="results" />

</b-list-group>
</template>
<script>
import NoResults from "@/components/db/search/NoResults"
import ResultItem from "@/components/db/ResultItem"
export default {
  name: "SearchResults",
  components: {
    ResultItem,
    NoResults
  },
  methods: {
    expandRadius() {
      this.$store.dispatch( "updateSearchRadius", this.$store.getters.maximum_radius )
    }
  },
  computed: {
    // The salesforce backend is returning duplicate results, so we deduplicate these results.
    // We should discuss the salesforce endpoint with HFA and resolve the bug at its source.
    uniqueResults() {
      return this.services.filter((result, position) => (this.services.map(r => r.id).indexOf(result.id) === position));
    },
  }
};
</script>
<style scoped lang="scss">
.search-results {
    box-shadow: 1rem 0 1rem rgba(0,0,0,0.2);
    z-index: 10;
    height: 100%;
    overflow: auto;
    border-radius: 0;
    display: block;

    .list-group-item.simple {
        display: grid;
        grid-auto-flow: column;
        align-content: center;
        align-items: center;
        justify-content: start;
        grid-gap: 0.5rem;
        @include caps;
        font-size: 0.8rem;

        .icon {
            height: 1rem;
            width: 1rem;
            color: $primary;
        }
    }

    .list-group-item.large {
        display: grid;
        grid-auto-flow: column;
        align-content: center;
        align-items: center;
        justify-content: start;
        grid-gap: 0.5rem;
        @include caps;
        font-size: 0.9rem;

        .icon {
            height: 1rem;
            width: 1rem;
            color: $primary;
        }
    }

}
</style>
