<template>
<section class="notes module"
         v-if="hasAny">
  <header>
    <span>
      Notes
    </span>
  </header>

  <ul>
    <li v-for="field in allFields"
        :key="field.id">
      <div>
        <b class="subhead">{{field.label}}:</b>
        {{field.value}}
      </div>
    </li>
  </ul>

</section>
</template>

<script>
export default {
  name: "Notes",
  components: {},
  props: [ "service" ],
  data() {
    return {
      fields: [
        'interpretation_services',
        'fees',
        'application_process',
        'wait_time',
        'licenses',
        'accreditations',
      ]
    }
  },
  computed: {
    hasAny() {
      return this.allFields.length > 0
    },
    allFields() {
      let results = []
      for ( let field of this.fields ) {
        let value = this.service[ field ]
        let obj = {
          label: field.replace( "_", " " ),
          value: value
        }
        if ( value ) {
          results.push( obj )
        }
      }
      return results
    },
  }
};
</script>
<style scoped lang="scss">
.notes {
    @include module;
    li {
        display: grid;
        grid-gap: 1rem;

        .subhead {
            text-transform: capitalize;
        }
        .icon {
            height: 1rem;
            color: theme-color-level(warning,2);
        }

    }

}
</style>
