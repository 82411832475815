<template>
<aside class="search-sidebar">
  <header>
    <section class="line nav">
      <b-link :to="backLink">
        <Icon type="ArrowLeft" />
      </b-link>

    </section>

    <section class="line address">
      <SearchAddress compact />
    </section>

    <section class="line more">
      <b-button variant="outline-primary"
                aria-label="Options"
                v-if="!showOptions && hasAddress"
                @click="toggleOptions">
        Options
      </b-button>

      <PDF ref="pdf"
           v-if="!showOptions && hasResults" />
    </section>
    <section class="line address">
      Please contact programs before visiting, as hours may have changed.
    </section>
  </header>

  <section class="searching"
           v-if="(isSearching && hasAddress )">
    <b-spinner type="grow"
               class="m-auto my-5"></b-spinner>
  </section>


  <section class="options"
           v-else-if="showOptions && hasAddress">
    <SearchOptions compact />
    <div>
      <b-button @click="toggleOptions"
                aria-label="Close">
        Close options
      </b-button>
    </div>
  </section>

  <SearchResults v-else-if="!showOptions" />



</aside>
</template>
<script>
import SearchAddress from "@/components/db/search/SearchAddress"
import SearchOptions from "@/components/db/search/SearchOptions"
import SearchResults from "@/components/db/search/SearchResults"
import PDF from "@/components/db/PDF"
import breakpoints from "@/scripts/Breakpoints"
export default {
  name: "SearchSidebar",
  components: {
    SearchAddress,
    SearchResults,
    SearchOptions,
    PDF
  },
  mixins: [ breakpoints ],
  props: [ "uid" ],
  data() {
    return {
      showOptions: false,
    }
  },
  watch: {
    hasAddress( newVal ) {
      if ( newVal == false ) {
        this.showOptions = false
      }
    }
  },
  computed: {
    showResults() {
      return !this.showOptions
    },
    backLink() {
      let query = new URLSearchParams( this.$route.query ).toString();
      if ( this.isMobile && this.uid ) {
        return "/results?" + query
      } else if ( this.isMobile && !this.uid ) {
        return "/?" + query
      } else {
        return "/?" + query
      }
    }
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions
      this.$emit( "show-options", this.showOptions )
    }
  }
};
</script>
<style scoped lang="scss">
.search-sidebar {
    display: grid;
    grid-template-rows: auto 1fr;
    background: theme-color-level(primary,-6);
    height: 100vh;

    header {
        display: grid;
        grid-auto-flow: row;
        align-items: center;
        align-content: center;
        z-index: 100;
        background: white;
        @include shadow;

        section.line {
            display: grid;
            grid-template-columns: auto;
            grid-auto-columns: auto;
            align-items: center;
            padding: 0.5rem;

            &.nav {
                a .icon {
                    width: 1.5rem;
                    height: 1.5rem;
                    color: $primary;
                }
            }
            &.address {}
            &.more {
                grid-gap: var(--pxs);
                grid-template-columns: auto auto;
                justify-content: start;
            }
        }

    }

    section.options {
        display: grid;
        padding: 2rem;
        width: 100%;
        grid-gap: 2rem;
        align-items: start;
        align-content: start;
        justify-content: start;
        background: theme-color-level(primary,-11.5);
        overflow-y: auto;

        > div {
            width: 100%;
        }

    }

    section.searching {
        height: 30vh;
        display: grid;
        align-items: center;
        justify-items: center;
    }

}
</style>
