import moment from "moment";
export default {
  created() {},
  data() {
    return {};
  },
  methods: {
    areSame(one, two) {
      return JSON.stringify(one) === JSON.stringify(two);
    },
    asDate(datetime) {
      return moment(datetime).format("MMM D");
    },
    time(timeslot, type, local, smart, abbr = true) {
      let t, m;
      if (local) {
        t =
          type === "open" ? timeslot.opens_at_local : timeslot.closes_at_local;
        m = moment.tz(t, timeslot.timezoneName);
      } else {
        t = type == "open" ? timeslot.opens_at : timeslot.closes_at;
        m = moment.utc(t);
      }
      if (smart) {
        let format = "h:mma";
        let tz = abbr ? " " + timeslot.timezoneAbbr : "";
        if (m.minutes() == 0) {
          format = "ha";
        }
        return m.format(format) + tz;
      } else {
        return m;
      }
    },
    opens(timeslot, local, smart, abbr) {
      return this.time(timeslot, "open", local, smart, abbr);
    },
    closes(timeslot, local, smart, abbr) {
      return this.time(timeslot, "close", local, smart, abbr);
    }
  }
};
