<template>
<div class="textview"
     :class="{'columns': !single}">
  <div v-for="group in asHeadingsAndColumns"
       :key="group.id"
       :class="group[0].isHeading ? 'other' :'paragraph'">
    <prismic-rich-text :field="group" />
  </div>
</div>
</template>

<script>
export default {
  name: "TextView",
  props: {
    text: [ Object, Array ],
    single: { type: Boolean, default: false }
  },
  components: {},
  computed: {
    asHeadingsAndColumns() {
      let result = []
      let temp = []
      let previous = null
      let maxPararaphs = 6

      for ( let item of this.text ) {
        let isHeading = item.type.startsWith( 'heading' ) ? true : false
        item[ 'isHeading' ] = isHeading

        if ( isHeading !== previous ) {
          if ( temp.length > 0 ) {
            result.push( temp )
            temp = []
          }
          temp.push( item )
          previous = isHeading
        } else {
          temp.push( item )
        }
      }

      if ( temp.length > 0 ) {
        result.push( temp )
      }
      // We are now segmented into headers & paragraphs
      // But we might have a really long segment, if so, we'll split it

      let balanced = []
      for ( let segment of result ) {
        if ( segment.length > maxPararaphs ) {
          let newSegments = Math.ceil( segment.length / maxPararaphs )
          for ( let i = 0; i < newSegments; i++ ) {
            let newPiece = segment.slice( i * maxPararaphs, i * maxPararaphs + maxPararaphs )
            balanced.push( newPiece )
          }
        } else {
          balanced.push( segment )
        }
      }

      return balanced
    },
    asColumns() {
      return this.slice.primary.show_as_columns
    },
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.textview {
    &.columns .paragraph {
        column-count: 2;
        column-gap: 2rem;
        column-fill: balance;
    }

    .paragraph + .paragraph {
        margin-top: var(--p6);
    }

    .other {
        margin: 2rem 0;
    }

    > .block-img {
        display: block;

        img {
            width: 100%;
            border-radius: 0.5rem;
        }
    }

    .mobile & {
        .paragraph {
            column-count: 1;
        }
    }

}
</style>
