import { render, staticRenderFns } from "./SliceTable.vue?vue&type=template&id=0ba46ef8&scoped=true&"
import script from "./SliceTable.vue?vue&type=script&lang=js&"
export * from "./SliceTable.vue?vue&type=script&lang=js&"
import style0 from "./SliceTable.vue?vue&type=style&index=0&id=0ba46ef8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ba46ef8",
  null
  
)

export default component.exports