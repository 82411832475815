<template>
<Slice>
  <b-container class="slice-list">
    <header>
      <h3 class="main-headline display">
        {{slice.primary.headline}}
      </h3>
      <div v-if="hasMainDescription"
           class="main-description">
        <prismic-rich-text :field="slice.primary.description" />
      </div>
    </header>

    <section class="items">
      <div class="item"
           v-for="item in slice.items"
           :key="item.id"
           :to="item.link.url">

        <template v-if="item.link.type == 'person'">
          <!-- <Person :item="item"></Person> -->
        </template>

        <template v-else>
          <div class="text">
            <div><b>{{item.label}}</b></div>
            <span>{{item.description}}</span>
          </div>
          <template v-if="item.link.url">
            <b-button variant="primary"
                      class="outline"
                      :aria-label="item.link.label"
                      :href="item.link.url">{{item.link.label || 'Link'}}</b-button>
          </template>
          <template v-else-if="item.link.uid">
            <b-button variant="primary"
                      class="outline"
                      :aria-label="item.link.label"
                      :to="'/'+item.link.uid">{{item.link.label || 'Link'}}</b-button>
          </template>
        </template>

      </div>
    </section>


  </b-container>
</Slice>
</template>

<script>
export default {
  name: "SliceList",
  props: [ 'item', 'slice' ],
  components: {},
  computed: {
    hasMainDescription() {
      return this.slice.primary.description && this.slice.primary.description.length > 0 && this.slice.primary.description[ 0 ].text != "" ? true : false
    }
  },
  methods: {

  }
};
</script>
<style scoped lang="scss">
.slice-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    padding: var(--p5) var(--p6);
    border-radius: 1rem;
    overflow: hidden;
    background: theme-color-level(primary, -11.8);
    // background: linear-gradient(-20deg, theme-color-level(primary, -11) 0%, white 80%);
    // @include shadow-lg;

    header {
        display: grid;
        grid-gap: 2rem;
        max-width: 75%;
    }

    .items {
        display: grid;
        border-radius: 1rem;
        overflow: hidden;
        width: 100%;
        justify-self: end;
        @include shadow-lg;

        .item {
            align-self: center;
            align-items: center;
            align-content: center;
            padding: var(--p2) var(--p5);
            grid-gap: var(--p1);
            background: white;
            display: grid;
            grid-template-columns: 1fr auto;
            border-top: 1px solid theme-color-level(primary,-11);
            .text {
                display: grid;
                align-items: center;
                align-content: center;
            }

            &:first-child {
                padding-top: var(--p3);
                border-top: none;
            }
            &:last-child {
                padding-bottom: var(--p3);
            }
        }
    }

    .mobile & {
        // border-radius: 0;
        background: none;

        header {
            padding: 0 var(--p4);
            max-width: 100%;
        }
        .item {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-auto-rows: auto;
            justify-items: start;
        }
    }

    .tablet & {}

    .desktop & {
        .items {
            width: 80%;
        }
    }

}
</style>
