<template>
<div class="search-address"
     :class="{'compact':compact}">

  <b-form @submit="submitForm">
    <label for="location">Please enter an address</label>
    <b-input-group class="address">
      <b-input v-model="address_field"
               @blur="submitForm"
               id="location"
               autocomplete="off"
               :size="size"
               placeholder="Please enter an address"></b-input>
      <b-input-group-append>
        <b-button v-if="hasAddress || isTypingAddress"
                  variant="light"
                  @click="resetForm"
                  aria-label="Reset">
          <Icon type="remove"></Icon>
        </b-button>
        <b-button v-else
                  variant="danger"
                  aria-label="Detect my location"
                  @click="automaticallyDetect">
          <b-spinner v-if="isAutomaticallyDetecting"
                     :size="size"
                     class="spinner"
                     type="grow"
                     variant="light" />
          <Icon v-else
                type="Location" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form>

</div>
</template>

<script>
import { Location } from "@/scripts/Location"
export default {
  name: "SearchAddress",
  mixins: [],
  props: { compact: { type: Boolean, default: false } },
  data() {
    return {
      address_field: "",
      locationClass: null
    }
  },
  watch: {
    formatted_address: {
      immediate: true,
      handler( val ) {
        if ( val && val != "" && val != null ) {
          this.address_field = val
        } else {
          this.address_field = ""
        }
      }
    }
  },
  mounted() {
    this.locationClass = new Location()
  },
  computed: {
    isTypingAddress() {
      return this.address_field.length > 0
    },
    isAutomaticallyDetecting() {
      return this.locationClass && this.locationClass.automatically_detecting
    },
    size() {
      return this.compact ? "sm" : "lg"
    }
  },
  methods: {
    resetForm() {
      this.address_field = ""
      this.$store.dispatch( "resetSearchAddress" )
    },
    submitForm( evt ) {
      evt.preventDefault()

      /* In order to submit:
        - Must not be a blank string
        - If we have a stored address it cannot be the same as the stored address
      */

      if ( this.address_field == "" ||
        ( this.store_search_address &&
          this.address_field === this.formatted_address ) ) {
        return
      }

      this.$store.dispatch( "updateSimpleAddress", this.address_field )

    },
    automaticallyDetect() {

      this.locationClass.getAddressAutomatically()
        .catch( error => {
          console.error( error )
        } )
    }
  }
};
</script>
<style scoped lang="scss">
.search-address {

    .icon {
        height: 1rem;
        width: 1rem;
    }

    .remove {
        color: $primary;
    }

    .input-group-append {
        border-left: 2px solid white;
    }

    &.compact .spinner-grow {
        height: 0.5rem;
        width: 0.5rem;
    }

}
</style>
