import Vue from "vue";
import VueRouter from "vue-router";
import Search from "@/views/Search.vue";
import Page from "@/views/Page.vue";
import Result from "@/views/Result.vue";
import Researchers from "@/views/Researchers.vue";
import NotFound from "@/views/NotFound.vue";
import SearchSidebar from "@/components/db/search/SearchSidebar.vue";
Vue.use(VueRouter);
import VueGtag from "vue-gtag";

const routes = [
  {
    path: "/",
    alias: "/home",
    name: "Home",
    component: Search,
    meta: {sidebar: false, navbar: false, footer: true}
  },
  {
    path: "/researchers",
    name: "Researchers",
    component: Researchers,
    meta: {sidebar: false, navbar: true, footer: false},
    props: true
  },
  {
    path: "/results",
    name: "Results",
    component: SearchSidebar,
    meta: {sidebar: false, navbar: false, footer: false},
    props: true
  },
  {
    path: "/result/:uid",
    name: "Result",
    component: Result,
    props: true,
    meta: {sidebar: false, navbar: false, footer: false}
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: NotFound
  },
  {
    path: "/:uid",
    name: "Page",
    component: Page,
    props: true,
    meta: {sidebar: false, navbar: true, footer: true}
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// ######################################
// Google Analytics
Vue.use(
  VueGtag,
  {
    config: {id: "UA-167893492-2"}
  },
  router
);

router.afterEach(to => {
  Vue.nextTick(() => {
    if (to.meta.title) {
      document.title = "Find Food — " + to.meta.title;
    } else {
      document.title = "Find Food";
    }
    window.scroll(0, 0);
  });
});

export default router;
