<template>
<Slice>
  <b-container class="slice-action"
               :class="classes">
    <header :class="{'hasMainVisual': hasMainVisual, 'hasMainHeadline':hasMainHeadline}">
      <b-img v-if="hasMainVisual"
             class="main-visual"
             :src="slice.primary.action_visual.wide.url">
      </b-img>

      <div v-if="hasMainHeadline"
           class="main-headline"
           :class="{'hasMainVisual': hasMainVisual}">
        <h2 class="display">{{slice.primary.headline}}</h2>
      </div>

    </header>

    <section v-if="hasMainText"
             class="main-description">
      <TextView :text="slice.primary.description"
                single />
    </section>

    <section class="actions"
             v-if="hasActions">

      <div class="action"
           v-for="item in filteredActions"
           :class="[actionClass(item), {'single':hasOneAction}]"
           :key="item.id">

        <prismic-rich-text class="text"
                           v-if="item.description.length > 0"
                           :field="item.description" />

        <SuperButton :variant="variant"
                     :size="actionClass(item)"
                     :href="item.link.url">
          <p>{{item.label}}</p>
          <Icon type="arrow"></Icon>
        </SuperButton>

      </div>

    </section>

  </b-container>
</Slice>
</template>

<script>
import TextView from "@/components/ui/TextView"
export default {
  name: "SliceAction",
  props: [ 'item', 'slice' ],
  components: { TextView },
  computed: {
    hasMainVisual() {
      let vis = this.slice.primary.action_visual
      return vis && vis.square.url ? true : false
    },
    hasMainHeadline() {
      return this.slice.primary.headline ? true : false
    },
    hasMainText() {
      let t = this.slice.primary.description.reduce( ( acc, x ) => acc + x.text.length, 0 );
      return this.slice.primary.description.length > 0 && t > 0 ? true : false
    },
    hasActions() {
      return this.filteredActions.length > 0 ? true : false
    },
    hasOneAction() {
      return this.filteredActions.length == 1
    },
    classes() {
      let tone = this.slice.primary.tone
      let c = [ tone ]
      if ( tone == 'important' || tone == 'urgent' ) {
        c.push( 'complex' )
      } else if ( this.hasMainText ) {
        c.push( 'complex' )
      } else if ( this.hasMainText ) {
        c.push( 'standard' )
      } else {
        c.push( 'simple' )
      }
      return c
    },
    filteredActions() {
      return this.slice.items.filter( a => a.label != null )
    },
    variant() {
      let v = this.slice.primary.tone
      if ( v == 'normal' ) {
        return 'primary'
      } else if ( v == 'important' ) {
        return 'secondary'
      } else if ( v == 'urgent' ) {
        return 'danger'
      } else {
        return 'primary'
      }
    }
  },
  methods: {
    actionClass( action ) {
      let hasDescription = action.description.length > 0 && action.description[ 0 ].text != "" ? true : false
      if ( hasDescription ) {
        return 'hasDescription'
      } else {
        return 'noDescription'
      }
    }
  }
};
</script>
<style scoped lang="scss">
.slice-action {
    display: grid;
    grid-template-columns: 1fr;
    margin: var(--p4) 0;
    padding: 0;
    border-radius: 1rem;
    overflow: hidden;

    &.normal {
        background: theme-color-level(primary, -11.5);
    }

    &.important {
        background: theme-color-level(primary, -11.5);
    }

    &.urgent {
        background: theme-color-level(danger, -11.5);
    }

    .main-description {
        padding: var(--p2) var(--p6);
    }

    &.complex {

        header {
            display: block;
            width: 100%;
            padding: var(--p4);
            margin-bottom: var(--p4);

            &.hasMainVisual:not(.hasMainHeadline) {
                min-height: 20rem;
            }

            .main-visual {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .main-headline {
                @include shadow-lg;
                width: 60%;
                background: white;
                z-index: 100;
                padding: var(--p5);
                border-radius: var(--p2);

                &.hasMainVisual {
                    @include shadow-xl;
                    margin: 6rem 0;
                }

                .tablet & {
                    width: 80%;
                }
                .mobile & {
                    width: 100%;

                }
            }
        }

    }

    &.standard {
        grid-template-columns: 1fr;
        grid-auto-columns: 1fr;
        grid-template-rows: auto 1fr;
        gap: 2rem;

        header {
            grid-row: 1;
            grid-column: span 2;
            display: block;
            background-color: none;
            padding: var(--p4);

            .main-visual {
                display: none;
            }

            .main-headline {
                // @include shadow-lg;
                // font-size: 2rem;
                // width: 60%;
                // background: white;
                z-index: 100;
                // padding: 3rem;
                // border-radius: 1rem;
            }
        }

        .main-description {
            grid-row: 2;
            // grid-area: text;
        }
    }

    &.simple {
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--p2);
        padding: var(--p4);

        .mobile & {
            grid-template-columns: 1fr;
        }

        header {
            display: block;
            width: 100%;
            background-color: none;
            // padding: var(--p4);

            .main-visual {
                display: none;
            }

            .main-headline {
                // @include shadow-lg;
                // font-size: 2rem;
                // width: 60%;
                // background: white;
                z-index: 100;
                // padding: 3rem;
                // border-radius: 1rem;
            }
        }

        .actions {
            padding: var(--p2);
        }

    }

    .actions {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--p1);
        padding: var(--p2) var(--p6);

        .action {
            display: grid;
            grid-gap: 1rem;
            justify-content: start;
            justify-items: start;
            align-items: start;
            align-content: start;

            .super-button {
                grid-area: action;
            }

            &.hasDescription {
                grid-template-areas: "text action";
                grid-template-columns: 1fr 1fr;
                grid-gap: var(--p6);
                padding-bottom: var(--p4);

                .text {
                    grid-area: text;

                    > *:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &.noDescription {
                grid-template-areas: "action";
                grid-template-columns: 1fr;
                grid-gap: 0;
            }
        }

        .mobile & {
            .action {
                grid-template-areas: "text" "action";
                grid-template-columns: 1fr;
                grid-auto-flow: row;
                padding-bottom: var(--p4);
            }
        }
    }

    .mobile & {
        border-radius: 0;
    }

}
</style>
