<template>
<button class="super-button"
        :class="[variant,{ 'disabled':disabled}]"
        :disabled="disabled"
        :aria-label="text"
        @click="openLink">
  <div class="content">
    <slot></slot>
  </div>
</button>
</template>
<script>
export default {
  name: 'SuperButton',
  components: {},
  props: {
    variant: { type: String, default: 'primary' },
    text: String,
    to: String,
    href: String,
    disabled: Boolean,
    click: Function
  },
  methods: {
    openLink() {
      if ( this.disabled ) {
        return
      } else {
        if ( this.click ) {
          this.click()
        } else if ( this.href ) {
          window.location.href = this.href
        } else if ( this.to ) {
          this.$router.push( this.to )
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.super-button {
    display: inline-flex;
    width: auto;
    cursor: pointer;
    border-radius: 1rem;
    overflow: hidden;
    background: yellow;
    border: 0;
    padding: 0;

    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
    }

    .content {
        display: inline-grid;
        grid-gap: 0;
        grid-template-columns: auto auto;
        align-items: center;
        align-content: center;
        justify-content: start;
        justify-items: start;
        justify-self: start;

        .icon {
            width: var(--fl);
            height: var(--fl);
            margin: var(--pl);
        }

        p {
            display: grid;
            padding: var(--pl);
            font-size: var(--fl);
            color: inherit;
            margin: 0;
            align-content: center;
            align-self: center;
            line-height: 100%;
            letter-spacing: 0;
        }

    }

    @each $color, $value in $theme-colors {
        &.#{$color} {
            background: $value;

            .content {
                color: color-yiq($value);
                p {
                    background: color-level($value,-1);
                }
            }

            &:hover:not(.disabled) {
                @include shadow-lg;
                z-index: 2;
                .icon {
                    transform: scale(1.3);
                }
                p {}
            }
        }
    }

    &.disabled {
        filter:grayscale(100%);
        cursor: default;
    }

}
</style>
