<template>
<div class="nav-item">

  <b-link v-if="item.id"
          class="cell"
          :href="item.id">
    <span>{{item.label}}</span>
  </b-link>

  <b-link v-else
          class="cell"
          :href="item.url"
          :target="item.target">
    <span>{{item.label}}</span>
  </b-link>


</div>
</template>

<script>
export default {
  name: "NavItem",
  components: {},
  props: {
    item: Object,
    selected: Object,
  },
  data() {
    return {
      childSelected: false,
    }
  },
  watch: {
    selected() {

      if ( this.item.items ) {
        let has = this.item.items.flat().filter( a => a.id == this.selected.id )
        let result =
          has && has.length == 1
        this.childSelected = result
      } else {
        this.childSelected = false
      }

    }
  },
  computed: {
    isSelected() {
      return this.selected && this.item.id == this.selected.id ? true : false
    },
    noSelected() {
      return this.selected && this.selected.label ? false : true
    },
    subItems() {
      if ( this.isSelected || this.childSelected ) {
        return this.item.items
      }
      return []
    },
    newLevel() {
      return parseInt( this.level || 0 ) + 1;
    }
  },
  methods: {
    select( thing ) {
      this.$emit( 'on-select', thing )
    }
  }
};
</script>
<style scoped lang="scss">
.nav-item {
    display: grid;
    grid-row-gap: 0.1rem;

    .cell {
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        border-radius: 0.75rem;
        user-select: none;
    }

}
</style>
