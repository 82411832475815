<template>
<div class="service module"
     :class="{'isMobile':isMobile}">

  <template v-if="complete">

    <section class="details"
             ref="details">

      <header class="top">

        <section class="back"
                 v-if="isMobile">
          <b-link :to="backLink">
            <Icon type="Remove" />
          </b-link>
        </section>

        <Name class="display headline"
              :service="item">
        </Name>
        <Address class="basics"
                 :item="item"
                 :location="item.location" />
        <div>
          <Organization class="basics"
                        :organization-id="item.organization_id" />
          <Taxonomy class="basics"
                    :service="item" />

        </div>

       <OpenStatus class="major"
                   :service="item" />

        <section class="map-preview"
                 v-if="isMobile">
          <Map :item="item"
               preview
               @route-results="handleRoute" />
        </section>

        <Icon type="Brush"></Icon>
      </header>

      <section class="middle">
        <Calendar class="module calendar"
                  type="regular"
                  :service="item" />

        <Calendar class="module calendar"
                  type="holiday"
                  :service="item" />

        <Requirements class="module requirements"
                      :service="item" />

        <Accessibility class="module"
                       :service="item" />

        <Languages class="module"
                   :service="item" />

        <Contact class="module"
                 :service="item" />

        <Notes class="module"
               :service="item" />

        <Directions class="module"
                    :service="item" />

        <Flag class="module"
              :service="item" />
      </section>

    </section>

    <section class="map"
             v-if="!isMobile">
      <Map :item="item"
           @route-results="handleRoute" />
    </section>
</template>

</div>
</template>

<script>
import { API } from '@/scripts/Api.js';
//
import Accessibility from "@/components/db/service/Accessibility"
import Address from "@/components/db/service/Address"
import Calendar from "@/components/db/service/Calendar"
import Contact from "@/components/db/service/Contact"
import Directions from "@/components/db/service/Directions"
import Flag from "@/components/db/service/Flag"
import Languages from "@/components/db/service/Languages"
import Map from "@/components/db/Map"
import Name from "@/components/db/service/Name"
import Notes from "@/components/db/service/Notes"
import OpenStatus from "@/components/db/service/OpenStatus"
import Organization from "@/components/db/service/Organization"
import Requirements from "@/components/db/service/Requirements"
import Taxonomy from "@/components/db/service/Taxonomy"
//
import breakpoints from "@/scripts/Breakpoints"

export default {
  name: "Service",
  components: {
    Accessibility,
    Organization,
    Requirements,
    Address,
    Taxonomy,
    OpenStatus,
    Calendar,
    Name,
    Languages,
    Contact,
    Notes,
    Directions,
    Flag,
    Map,
  },
  props: [ "uid" ],
  mixins: [ breakpoints ],
  data() {
    return {
      notFound: false
    }
  },
  watch: {
    item: {
      immediate: true,
      handler( newVal, oldVal ) {
        if ( newVal && newVal != oldVal ) {
          document.title = newVal.name;
        }
      }
    },
    uid: {
      immediate: true,
      handler() {
        API.getSpecificService( this.uid )
          .then( () => {
            // this.$SEARCH.generateURLQuery()
            this.notFound = false
          } )
          .catch( error => {
            console.error( "Service Page", error )
            this.notFound = true
          } )
      }
    }
  },
  computed: {
    item() {
      return API.findService( this.uid )
    },
    complete() {
      return this.item && this.item.complete ? true : false
    },
    backLink() {
      let query = new URLSearchParams( this.$route.query ).toString();
      return "/results?" + query
    }
  },
  methods: {
    handleRoute( result ) {
      this.currentRoute = result.routes[ 0 ]
    },
  }
};
</script>
<style scoped lang="scss">
.service {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;

    &.isMobile {
        grid-template-columns: 1fr;
    }

    section.details {
        box-shadow: 1rem 0 1rem rgba(0,0,0,0.2);
        z-index: 1;
        overflow-y: auto;
        grid-template-rows: auto auto auto;
        align-items: start;
        padding-bottom: 10rem;

        header.top {
            display: grid;
            align-items: start;
            background: theme-color-level(primary, -2);
            background: linear-gradient(0deg, theme-color-level(primary, -3) 0%, theme-color-level(primary, 2) 100%);
            // padding: var(--pxxl);
            grid-gap: 2rem;
            padding: 10%;
            margin-bottom: 3rem;

            .back {
                .icon {
                    height: 2rem;
                    width: 2rem;
                    color: white;
                }
            }

            .module {
                padding: 0;
                * {
                    color: white;
                }
            }

            .Brush {
                position: absolute;
                bottom: 0;
            }
        }

        .module {
            padding: var(--p1) var(--p4);
        }

    }

    section.middle {
        display: grid;
        grid-column-gap: 1rem;
        grid-row-gap: 2rem;
        align-items: start;
        margin: 5%;
    }

    .map-preview {
        width: 100%;
        height: 70vh;
        @include shadow-lg;
    }

    // .map {
    //     width: 100%;
    //     height: 100%;
    // }

    // .desktop & {
    //     .nav {
    //         display: none;
    //     }
    // }
    //
    // .mobile & {
    //     grid-template-columns: 1fr;
    //
    //     .nav {
    //
    //         .icon {
    //             color: white;
    //         }
    //     }
    //     .map {
    //         display: none;
    //     }
    // }

}
</style>
