
<template>
<div class="research-map">
  <div class="map-actual"
       :id="mapId" />
</div>
</template>
<script>
import { Location } from "@/scripts/Location"
export default {
  name: "ResearchMap",
  mixins: [],
  data() {
    return {}
  },
  props: {},
  watch: {},
  computed: {
    mapId() {
      return "map"
    },
    mapElement() {
      return document.getElementById( this.mapId )
    },
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      this.$nextTick( () => {
        new Location( {
          mapElement: this.mapElement,
          origin: 'United States',
        } ).loadResearchMap()
      } )
    },
  }
};
</script>
<style scoped lang="scss">
.research-map {
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;
    .map-actual {
        height: 100%;
        width: 100%;
    }

}
</style>
