import moment from "moment";

import TimeSpace from "@mapbox/timespace";
export class CalendarClass {
  constructor(service) {
    this.service = service;
    moment.updateLocale("en", {
      calendar: {
        lastDay: "[yesterday]",
        sameDay: "[today]",
        nextDay: "[tomorrow]",
        lastWeek: "[last] dddd",
        nextWeek: "[next] dddd",
        sameElse: "LL"
      }
    });
  }

  injectTimeZone(a) {
    let timestamp = Date.now();
    let point = [this.service.location.lng, this.service.location.lat];
    let time = TimeSpace.getFuzzyLocalTimeFromPoint(timestamp, point);
    a.timezoneName = time.tz();
    a.timezoneAbbr = time.format("z");
    a.timezoneOffset = time.format("Z");
    return a;
  }

  regularSchedule() {
    // Clean up the regular schedule before ingesting
    return (
      this.service.regular_schedule
        // Make sure it has times
        .filter(a => a.opens_at != null && a.closes_at != null)
        .filter(a => a.opens_at < a.closes_at)
        .map(a => this.injectTimeZone(a))
    );
  }

  scheduleAsDays() {
    let allDays = [];
    for (let day of this.regularSchedule()) {
      for (let i = 0; i <= 12; i++) {
        let dayObject = this.createDayObject(day, false, i);
        allDays.push(dayObject);
      }
    }
    return allDays;
  }

  holidaySchedule() {
    // Clean up the holiday schedule before ingesting
    if (!this.service.holiday_schedule) {
      return [];
    }
    return (
      this.service.holiday_schedule
        // Make sure it has times OR it's closed
        .filter(a => (a.opens_at != null && a.closes_at != null) || a.closed)
        // Sort by date
        .sort((a, b) => {
          if (a.start_date < b.start_date) {
            return 1;
          } else if (a.start_date < b.start_date) {
            return -1;
          } else {
            return 0;
          }
        })
        .map(a => this.injectTimeZone(a))
    );
  }

  holidayScheduleAsDays() {
    let allDays = [];
    //Cycle through each "holiday span"
    for (let span of this.holidaySchedule()) {
      let startDay = moment(span.start_date);
      let endDay = moment(span.end_date);
      let spanDays = endDay.diff(startDay, "days");
      // Generate a day for each day in the holiday span
      for (let i = 0; i <= spanDays; i++) {
        let dayObject = this.createDayObject(span, true, i);
        allDays.push(dayObject);
      }
    }
    return allDays;
  }

  combinedScheduleDays() {
    return this.scheduleAsDays()
      .concat(this.holidayScheduleAsDays())
      .sort((a, b) => {
        if (moment(a.date).isAfter(b.date)) {
          return 1;
        } else if (moment(a.date).isBefore(b.date)) {
          return -1;
        } else {
          return 0;
        }
      });
  }

  combinedCleanScheduleDays() {
    // Remove all regular days that are superceded by a holiday schedule
    let holidays = this.holidayScheduleAsDays().flatMap(holiday =>
      moment(holiday.date).format("YYYY-MM-DD")
    );

    let clean = this.scheduleAsDays().filter(regular => {
      return !holidays.includes(moment(regular.date).format("YYYY-MM-DD"));
    });
    return clean.concat(this.holidayScheduleAsDays()).sort((a, b) => {
      if (moment(a.date).isAfter(b.date)) {
        return 1;
      } else if (moment(a.date).isBefore(b.date)) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  todaysSchedule() {
    return this.combinedCleanScheduleDays()
      .filter(day => moment(day.date).isSame(moment(), "day"))
      .sort((a, b) => {
        if (moment(a.opens_at).isAfter(moment(b.opens_at))) {
          return 1;
        } else if (moment(b.opens_at).isAfter(moment(a.opens_at))) {
          return -1;
        } else {
          return 0;
        }
      });
  }

  currentSlot() {
    for (let slot of this.todaysSchedule()) {
      let now = moment.tz(slot.timezoneName);
      let aa = moment(slot.opens_at).diff(now, "minutes");
      let bb = moment(slot.closes_at).diff(now, "minutes");
      if (aa <= 0 && bb >= 0) {
        return slot;
      }
    }
    return null;
  }

  nextSlot() {
    let now = moment();
    return (
      this.todaysSchedule()
        .sort((a, b) => {
          if (a.opens_at > b.opens_at) {
            return 1;
          } else if (a.opens_at < b.opens_at) {
            return -1;
          } else {
            return 0;
          }
        })
        .filter(a => a != this.currentSlot)
        .find(a => moment(a.closes_at).isAfter(now)) || null
    );
  }

  nextDay() {
    let now = moment();
    let tomorrow = moment()
      // .add(1, "days")
      .startOf("day");

    return (
      this.combinedCleanScheduleDays()
        .filter(a => a.isClosed == false)
        .filter(a => moment(a.closes_at).isAfter(now))
        .sort((a, b) => {
          if (a.opens_at > b.opens_at) {
            return 1;
          } else if (a.opens_at < b.opens_at) {
            return -1;
          } else {
            return 0;
          }
        })
        .find(a => moment(a.opens_at).isAfter(tomorrow)) || null
    );
  }

  holidayCalendar() {
    let week = [];
    let holidays = this.combinedScheduleDays()
      .filter(a => a.isHoliday)
      .filter(a => moment(a.opens_at).isAfter(moment().startOf("day")))
      .filter((v, i, a) => a.findIndex(t => t.date === v.date) === i);

    for (let d = 0; d < 300; d++) {
      let date = moment()
        .startOf("day")
        .add(d, "days");
      let matches = holidays.filter(a => moment(a.date).isSame(date, "day"));

      // Only add this to the results if there are time slots
      if (matches.length > 0) {
        week.push({
          label: date.format("MMM D"),
          slots: matches
        });
      }
    }

    return week;
  }

  weeklyCalendar() {
    let week = [];
    let uniques = this.combinedScheduleDays()
      .filter(a => !a.isHoliday)
      .filter((v, i, a) => a.findIndex(t => t.uid === v.uid) === i);

    for (let weekday = 1; weekday <= 7; weekday++) {
      let matches = uniques
        .filter(a => a.weekday == weekday)
        .sort((a, b) => {
          if (moment(a.opens_at).isAfter(b.opens_at)) {
            return 1;
          } else if (moment(a.opens_at).isBefore(b.opens_at)) {
            return -1;
          } else {
            return 0;
          }
        });

      if (matches.length > 0) {
        week.push({
          label: moment()
            .isoWeekday(weekday)
            .format("ddd"),
          weekday: weekday,
          slots: matches
        });
      }
    }
    return week;
  }

  nextWeekday(targetWeekday) {
    const today = moment().isoWeekday();
    if (today == targetWeekday) {
      return moment().format("YYYY-MM-DD");
    } else if (today < targetWeekday) {
      return moment()
        .weekday(targetWeekday)
        .format("YYYY-MM-DD");
    } else {
      return moment()
        .add(1, "weeks")
        .weekday(targetWeekday)
        .format("YYYY-MM-DD");
    }
  }

  createDayObject(day, isHoliday, dayOffset) {
    // console.log("CAL", "createDayObject", day, isHoliday, dayOffset);
    let date;

    if (isHoliday) {
      date = moment.tz(day.start_date, day.timezoneName);
    } else {
      date = moment.tz(this.nextWeekday(day.weekday), day.timezone);
    }

    let weekday = parseInt(day.weekday || date.isoWeekday());
    if (isHoliday) {
      date = date.add(dayOffset, "days").format("YYYY-MM-DD");
    } else {
      date = date.add(dayOffset, "weeks").format("YYYY-MM-DD");
    }
    let tz = day.timezoneOffset || "";
    let oat = day.opens_at || "00:00:00";
    let cat = day.closes_at || "00:00:00";

    let opens_at = date + "T" + oat + tz;
    let closes_at = date + "T" + cat + tz;
    let opens_at_local = date + " " + oat;
    let closes_at_local = date + " " + cat;
    let details = day.details || "";

    let obj = {
      weekday,
      date,
      opens_at,
      closes_at,
      details,
      opens_at_local,
      closes_at_local,
      isHoliday: isHoliday,
      span: isHoliday ? day : null,
      isClosed: day.closed == "TRUE" ? true : false,
      uid: day.id,
      timezoneName: day.timezoneName,
      timezoneAbbr: day.timezoneAbbr,
      timezoneOffset: day.timezoneOffset
    };
    return obj;
  }
}
