<template>
<b-navbar class="nav-header"
          toggleable="lg"
          :class="{'scrolled':isScrolled}">

  <b-container class="extra"
               fluid>


    <template v-if="!custom">

      <Icon type="Menu"
            class="d-md-block d-lg-none"
            v-b-toggle.sidebar-1></Icon>

      <b-collapse id="navbar-toggle-collapse"
                  v-if="!nobreadcrumb"
                  is-nav>
        <b-breadcrumb :class="{'invert':invert}">
          <b-breadcrumb-item href="/home"
                             aria-label="Home">
            <Icon type="Home"
                  aria-hidden="true"></Icon>
          </b-breadcrumb-item>
          <b-breadcrumb-item v-for="item in breadcrumb"
                             :class="{'active': item.active}"
                             :active="item.active"
                             :href="!item.active ? item.href : null"
                             :key="item.id">{{item.text}}</b-breadcrumb-item>
        </b-breadcrumb>
      </b-collapse>

      <b-form-input v-if="!nosearch"
                    placeholder="Search..."
                    class="search">
        Search
      </b-form-input>
    </template>

    <slot>

    </slot>


  </b-container>


</b-navbar>
</template>

<script>
export default {
  name: "NavHeader",
  components: {},
  props: {
    invert: { type: Boolean, default: false },
    nosearch: { type: Boolean, default: false },
    nobreadcrumb: { type: Boolean, default: false },
    custom: { type: Boolean, default: false },
    to: String,
  },
  data() {
    return { isScrolled: false }
  },
  created() {
    window.addEventListener( 'scroll', this.wasScrolled );
  },
  destroyed() {
    window.removeEventListener( 'scroll', this.wasScrolled );
  },
  computed: {
    breadcrumb() {
      return []
    }
  },
  methods: {
    wasScrolled() { this.isScrolled = window.scrollY > 0 ? true : false }
  }

};
</script>
<style scoped lang="scss">
.nav-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    transition: all 0.2s ease-in-out;
    background: inherit;
    background: white;
    padding: 0;

    &.scrolled {
        @include shadow-lg;
    }

    .icon {
        height: 1.5rem;
        width: 1.5rem;
        margin: var(--pl);
    }

    .extra {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
        width: 100%;
    }

}
</style>
