<template>
<div class="address module"
     v-if="physical_address">

  <div class="addresses">
    <span v-if="street"
          class="street">{{street}}</span>
    <span v-if="city"
          class="city">{{city}}</span>
    <span v-if="state_province"
          class="state_province">{{state_province}}</span>
    <span v-if="postal_code"
          class="state_province">{{postal_code}}</span>
  </div>

  <b-button class="getDirections"
            size="sm"
            href="#directions"
            @click="track"
            aria-label="Get directions"
            variant="outline-white">
    Get directions
  </b-button>



</div>
</template>

<script>
import {API} from '@/scripts/Api.js';
export default {
  name: "Address",
  components: {},
  props: [ "location", "item" ],
  data() {
    return {
      physical_address: null
    }
  },
  computed: {
    street() {
      return this.physical_address.address_1
    },
    city() {
      return this.physical_address.city
    },
    state_province() {
      return this.physical_address.state_province
    },
    postal_code() {
      return this.physical_address.postal_code
    },
  },
  watch: {
    item: {
      immediate: true,
      handler() {
        this.getAddress()
      }
    }
  },
  methods: {
    track() {
      this.$gtag.event( "get_directions", {
        event_category: "directions",
        event_label: this.item.id,
        value: 1
      } );
    },
    getAddress() {
      API.fetchSpecificLocation( this.location.location_id )
        .then( ( result ) => {
          // console.warn("warn", result)
          this.physical_address = result[0].physical_address[0]
        } )
        .catch( error => {
          console.error( "Address", error )
          // this.notFound = true
        } )
    },
  }
};
</script>
<style scoped lang="scss">
.address {
    display: grid;
    align-items: center;
    grid-template-columns: auto;
    justify-content: start;
    justify-items: start;
    grid-gap: 1rem;

    span {
        display: inline;
        color: white;

        &:not(:last-of-type)::after {
            content: ", ";
        }
    }

    &.print {
        font-size: 0.7rem;
        font-weight: bold;

        .getDirections {
            display: none;
        }
    }
}
</style>
