<template>
<div class="nav-footer">
  <Icon type="BrushTop"></Icon>
  <Slice>
    <div class="elements">


      <div class="columns">
        <section class="brand">
          <b-link href="/"
                  aria-label="Home">
            <Icon type="Logo"></Icon>
          </b-link>
        </section>

        <section class="info">
          <div class="list">
            <NavItem v-for="link in nav"
                     name="navitem"
                     :key="link.uid"
                     :item="link" />
          </div>
        </section>

        <section class="info">
          <div class="contact-info">
            <p>50 Broad St.</p>
            <p>Suite 1103</p>
            <p>New York, NY 10004</p>
            <p>(212) 825-0028</p>
          </div>

          <div class="legal smallcaps">© {{year}} HUNGER FREE AMERICA</div>
        </section>


      </div>
    </div>

  </Slice>

</div>
</template>

<script>
export default {
  name: "NavFooter",
  data() {
    return {
      nav: []
    }
  },
  computed: {
    year() {
      return new Date().getFullYear()
    }
  },
  mounted() {
    this.getNavItems()
  },
  methods: {
    getNavItems() {
      this.$prismic.client.getSingle(
          'navigation' )
        .then( ( document ) => {
          this.nav = document.data.footer_links.map( a => {
            let id = null
            if ( a.internal_link ) {
              id = a.internal_link
            } else if ( a.external_link && a.external_link.link_type == 'Document' ) {
              id = a.external_link.slug
            }
            let url = null
            let target = "_self"
            if ( a.external_link && a.external_link.link_type == 'Web' ) {
              url = a.external_link.url
              target = a.external_link.target
            }
            return {
              label: a.label,
              id: id,
              url: url,
              target: target
            }
          } )
        } )
    }
  },
};
</script>
<style scoped lang="scss">
.nav-footer {
    margin-top: var(--p6);
    background: theme-color-level(primary,-11.9);

    .BrushTop {
        position: absolute;
        top: 0;
    }

    .elements {
        padding-top: 6rem;
        padding-bottom: 6rem;
        display: grid;
    }

    .columns {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: var(--p4) var(--p1);

        section {
            @include grid-v;
            grid-gap: 1rem;
            align-items: start;
            align-content: start;

            .list {
                @include grid-v;
                grid-row-gap: 0.2rem;
            }

            a {
                color: $primary;
            }

            &.info {
                padding: 0.5rem;
            }
        }

    }

    .mobile & {
        .slice {
            padding: var(--p6);
        }
        .elements {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }

    .brand {
        @include grid-v;
        grid-gap: 0.5rem;
        align-items: start;
        align-content: start;
        grid-gap: 2rem;

        .Logo {
            width: 6rem;
        }

    }

    .contact-info {
        p {
            font-size: 0.8rem;
            margin: 0;
        }
    }

    .legal {
        font-size: 0.7rem;
    }
}
</style>
