<template>
<section class="directions module"
         v-if="hasAny">
  <a name="directions"></a>
  <header>
    <span>
      Directions
    </span>
  </header>

  <TransportationToggle v-if="hasAddress" />


  <DirectionItem v-if="pref === 'DRIVING'"
                 ref="driving"
                 :service="service"
                 travelmode="DRIVING" />
  <DirectionItem v-if="pref === 'TRANSIT'"
                 ref="transit"
                 :service="service"
                 travelmode="TRANSIT" />
  <DirectionItem v-if="pref === 'WALKING'"
                 ref="walking"
                 :service="service"
                 travelmode="WALKING" />



</section>
</template>

<script>
import DirectionItem from "@/components/db/service/DirectionItem"
import TransportationToggle from "@/components/db/TransportationToggle"
export default {
  name: "Directions",
  components: {
    TransportationToggle,
    DirectionItem
  },
  props: [ "service" ],
  data() {
    return {}
  },
  computed: {
    hasAny() {
      return this.service.location && this.store_search_address ? true : false
    },
    pref() {
      return this.transportation_preference
    }
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.directions {
    @include module;
    grid-gap: 1rem;

    header {
        display: grid;
        grid-template-columns: 1fr auto;
        justify-content: space-between;
    }

}
</style>
