<template>
<div class="researchers">


  <content :class="{'isMobile':isMobile}">

    <b-container fluid
                 nopadding
                 v-if="isShowingMap">
      <b-row class="actions p-3">
        Showing {{totalShowing}} results
        <b-button @click="reset">Search again</b-button>
      </b-row>
      <ResearchMap />
    </b-container>
    <b-container v-else-if="!isResearching">
      <h1>For Researchers</h1>
      <p>
        Those interested can access data from larger areas of the country for research purposes.
      </p>
      <ResearchOptions />
      <b-button size="lg"
                @click="showMap">Search</b-button>
    </b-container>

    <b-spinner type="grow"
               class="m-auto my-5"
               v-if="isResearching"></b-spinner>
  </content>

</div>
</template>
<script>
import ResearchMap from "@/components/db/researchers/ResearchMap"
import ResearchOptions from "@/components/db/researchers/ResearchOptions"
import breakpoints from "@/scripts/Breakpoints"
export default {
  name: "Researches",
  components: {
    ResearchOptions,
    ResearchMap,
  },
  mixins: [ breakpoints ],
  mounted() {

  },
  data() {
    return {
      isShowingMap: false,
      isResearching: false,
      totalShowing: null,
    }
  },
  computed: {},
  methods: {
    showMap() {
      console.log( "Begin search" )
      this.isResearching = true
      this.$API.fetchAllServicesForResearch().then( results => {
        console.log( "Got results!", results )
        this.isShowingMap = true
        this.isResearching = false
        this.totalShowing = results.length
      } )
    },
    reset() {
      this.isShowingMap = false
      // this.$store.dispatch( "resetResearchOptions" )
    }
  }
};
</script>
<style scoped lang="scss">
.researchers {
    display: grid;
    grid-template-areas: "nav" "content";
    grid-template-rows: auto 1fr;
    height: 90vh;
    position: relative;

    content {
        grid-area: content;
        display: grid;

        .container {
            padding: 0;
            @include grid-v;
            align-items: start;
            align-content: start;
            grid-gap: 2rem;
            justify-content: start;
            justify-items: start;
        }

    }

    .actions {
        @include grid-h;
    }

}
</style>
