<template>
<div class="open-status"
     :class="[status.status, {'condensed':small}]">
  <div class="small"
       :class="status.status"
       v-if="small">
    {{status.label}}
  </div>

  <template v-else>

    <div class="label">
      <Icon type="warning"
            v-if="status.status == 'bad'"></Icon>
      {{status.label}}
    </div>
    <div class="message"
         v-if="status.message">{{status.message}}</div>
    <b-badge class="note"
             pila
             v-if="status.note">{{status.note}}</b-badge>
  </template>
</div>
</template>

<script>
import Mixins from "@/scripts/Mixins"
// import Calendar from "@/scripts/Calendar"
export default {
  name: "OpenStatus",
  mixins: [ Mixins ],
  components: {},
  props: {
    'service': Object,
    'small': {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  watch: {
    status: {
      immediate: true,
      handler( newVal, oldVal ) {
        if ( newVal != oldVal ) {
          this.$emit( "status", newVal )
        }
      }
    }
  },
  computed: {
    currentSlot() {
      return this.service.currentSlot
    },
    nextSlot() {
      return this.service.nextSlot
    },
    nextDay() {
      return this.service.nextDay
    },
    status() {
      if ( this.currentSlot ) {
        // let closes = this.closes( this.currentSlot )
        let closes_local = this.closes( this.currentSlot, "local", "smart" )
        let reopens = this.nextSlot && this.nextSlot.uid != this.currentSlot.uid ? 'Also reopens later today' : ''
        let holiday = this.currentSlot.isHoliday ? 'Holiday hours' : ''
        return {
          status: 'good',
          variant: 'white',
          label: `Open until ${closes_local}`,
          message: `${reopens}`,
          note: `${holiday}`
        }
      } else if ( this.nextSlot ) {
        // let abbr = this.nextSlot.timezone
        // let opens_local = this.smartTime( this.nextSlot.opens_at_local, abbr )
        let opens = this.opens( this.nextSlot )
        let opens_local = this.opens( this.nextSlot, true, true )
        return {
          status: 'bad',
          variant: 'danger',
          label: `Opens later`,
          message: `Opens again
          ${opens.fromNow()}
           at ${opens_local}`,
          note: ``
        }
      } else if ( this.nextDay ) {
        // let opens_local = this.nextDay.opens_at_local
        // let abbr = this.nextDay.timezone
        let opens = this.time( this.nextDay, "open", true )
        let smart = this.time( this.nextDay, "open", true, true )
        return {
          status: 'bad',
          variant: 'danger',
          label: `Closed`,
          message: `Opens again ${opens.calendar()} at ${smart}`,
          note: ``
        }
      }
      return {
        status: 'bad',
        variant: 'danger',
        label: 'Closed',
        message: '',
        note: null
      }
    }
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.open-status {
    justify-self: start;

    &.major {
        display: grid;
        grid-gap: 0.1rem;
        grid-template-rows: auto auto;
        padding: var(--pm);
        align-items: center;
        color: white;
        border-radius: 1rem;
        justify-content: start;
        justify-items: start;

        &.good {
            background: theme-color-level(success,0);
            * {
                color: $primary;

            }
        }

        &.bad {
            background: theme-color-level(danger,0);
            color: white;
        }
    }

    div {
        color: white;
    }

    .label {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 0.5rem;
        align-items: center;
        justify-content: start;
        font-weight: bold;

        .icon {
            height: var(--pl);
            width: var(--pl);
        }
    }

    .message {
        // font-size: 0.8rem;
    }

    &.condensed {
        justify-self: start;

        .badge * {
            break-word: break-all;
            display: inline;
            word-wrap: break-all;
            word-break: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;

        }
        .badge-success {
            color: $primary;
        }

        .small {
            display: grid;
            grid-auto-flow: column;
            grid-gap: 0.5rem;
            align-items: center;
            align-content: center;

            @include smallcaps;
            letter-spacing: 0.05rem;
            font-size: 0.7rem;
            padding: 0.2rem;
            border-radius: 0.2rem;

            &.bad {
                color: $danger;
            }
            &.good {
                color: theme-color-level(success,12);
                background: theme-color-level(success,0);
            }

            .icon {
                display: none;
                height: 0.7rem;
                color: $danger;
            }
        }
    }
}
</style>
