
<template>
<b-list-group-item class="result-item"
                   active-class="active"
                   :class="[{'closed': isClosed}, openStatus.status]"
                   tag="li"
                   :to="link">

  <div class="name">
    {{service.name}}
  </div>

  <div class="distance"
       :class="{'bad':isFar}">
    <b-badge variant="danger"
             v-if="isFar">FAR</b-badge>
    {{distanceInMiles}}
  </div>

  <div class="status"
       :class="openStatus.status">
    <OpenStatus :service="service"
                @status="handleStatus"
                small />
  </div>

</b-list-group-item>
</template>
<script>
import OpenStatus from "@/components/db/service/OpenStatus"
export default {
  name: "ResultItem",
  components: {
    OpenStatus,
  },
  data() {
    return {
      openStatus: {}
    }
  },
  props: {
    'service': Object
  },
  mounted() {},
  computed: {
    isActive() {
      return this.$route.params.uid == this.service.id
    },
    routeLeg() {
      return this.currentRoute ? this.currentRoute.legs[ 0 ] : false
    },
    routeDuration() {
      return this.routeLeg ? this.routeLeg.duration.text : "--"
    },
    routeMode() {
      return this.store_search_options.transportation_preference
    },
    miles() {
      return Math.round( this.service.distance * 0.00062137 )
    },
    isFar() {
      return this.miles > 30
    },
    distanceInMiles() {
      if ( this.service.distance ) {

        if ( this.miles == 0 ) {
          return Math.round( this.service.distance * 0.00062137 * 10 ) / 10 + "  mi"
        } else {
          return this.miles + " mi"
        }
      } else {
        return ""
      }
    },
    isClosed() {
      return this.openStatus && this.openStatus.status == 'bad'
    },
    link() {
      let query = new URLSearchParams( this.$route.query ).toString();
      return '/result/' + this.service.id + "?" + query
    },
  },
  methods: {
    handleStatus( result ) {
      this.openStatus = result
    },

  }
};
</script>
<style scoped lang="scss">
.result-item.list-group-item {

    align-content: center;
    align-items: center;
    border: 0;
    border-bottom: 1px solid theme-color-level(primary, -11);
    border-radius: 0;
    display: grid;
    grid-gap: 0.2rem;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    padding: 1rem;
    min-height: 5rem;

    & +.list-group-item {
        margin-top: 0;
        border-top-width: 0;
    }

    .distance {
        display: grid;
        grid-auto-flow: row;
        align-items: center;
        grid-column: 2;
        grid-row: 1 / span 2;
        font-size: 0.7rem;
        color: theme-color-level(primary,-2);
        font-weight: 600;

        &.bad {
            color: theme-color-level(danger,2);
        }
    }

    .name {
        font-size: 0.9rem;
        font-weight: bold;
    }

    &.active {
        background: linear-gradient(-90deg, theme-color-level(primary, -11) 0%, theme-color-level(primary, -12) 100%);
    }

    .mobile & {
        .name {
            font-size: 1.2rem;
        }
    }

    .status {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        grid-gap: 0.3rem;
        color: inherit;
        font-size: 0.7rem;

        &.bad {
            display: none;
        }
    }

}
</style>
