import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
Vue.use(Vuex);
import _ from "lodash";
import {SEARCH} from "@/scripts/Search";
import {CalendarClass} from "@/scripts/Calendar";
import {Location} from "@/scripts/Location";

export default new Vuex.Store({
  state: {
    domain: {
      full: "http://findfood.hungerfreeamerica.com",
      simple: "findfood.hungerfreeamerica.com"
    },
    hotline: {
      en: "1-866-3-HUNGRY",
      es: "1-877-8-HAMBRE"
    },
    isPreloaded: false,
    isSearching: false,
    simple_address: null,
    store_search_options: {
      search_address: null,
      search_radius: null,
      search_bounds: null,
      primary_types: null,
      secondary_types: null,
      transportation_preference: "DRIVING"
    },
    store_research_options: {
      primary_types: null,
      secondary_types: null
    },
    store_search_possible_options: {
      radius: [
        {
          text: "20 miles",
          default: true,
          value: 32000
        }
      ],
      transportation: [
        {
          text: "Public transit",
          value: "TRANSIT"
        },
        {
          text: "Driving",
          value: "DRIVING"
        },
        {
          text: "Walking",
          value: "WALKING"
        }
      ]
    },
    stories: [],
    locations: null,
    api_services: [],
    taxonomy_from_api: null,
    taxonomy_codes: [
      {
        code: "BD-5000.8300",
        text: "Soup Kitchen",
        type: "primary",
        default: true,
        id: null
      },
      {
        code: "BD-1800.2000",
        text: "Food Pantry",
        type: "primary",
        default: true,
        id: null
      },
      {
        code: "NL-6000.2000-220",
        text: "SNAP",
        type: "primary",
        default: true,
        id: null
      },
      {
        code: "NL-6000.9500-950",
        text: "WIC",
        type: "primary",
        id: null
      },
      {
        code: "BD-5000.3500",
        text: "Meal Delivery",
        type: "primary",
        id: null
      },
      {
        code: "BD-2400.2590",
        text: "Grocery Delivery",
        type: "primary",
        id: null
      },
      {
        code: "BD-2400.2250",
        text: "Farmer's Market",
        type: "primary",
        id: null
      },
      {
        code: "BD-1875.2000",
        text: "Food Bank",
        type: "research",
        id: null
      },
      {
        code: "OPEN",
        text: "Open Today",
        type: "secondary",
        default: false,
        special: true,
        id: "OPEN"
      },
      {
        code: "ES",
        text: "Spanish-speaking",
        type: "secondary",
        special: true,
        id: "ES"
      },
      {
        code: "SR",
        text: "Seniors only",
        type: "secondary",
        special: true,
        id: "SR"
      },
      {
        code: "JR",
        text: "18y & under",
        type: "secondary",
        special: true,
        id: "JR"
      },

      {
        code: "BD-2400.4900",
        text: "Accepts EBT",
        type: "secondary",
        id: null
      },
      {
        code: "DELIVERY",
        text: "Delivery",
        type: "secondary",
        special: true,
        id: null
      },
      {
        code: "BD-1800.8200-380",
        text: "Kosher",
        type: "secondary",
        id: null
      },
      {
        code: "BD-1800.8200-320",
        text: "Halal",
        type: "secondary",
        id: null
      },
      {
        code: "BD-1800.8200-900",
        text: "Vegetarian",
        type: "secondary",
        id: null
      }
    ]
  },
  mutations: {
    SET_IS_PRELOADED(state, value) {
      Vue.set(state, "isPreloaded", value);
    },
    SET_IS_SEARCHING(state, value) {
      Vue.set(state, "isSearching", value);
    },
    SET_SIMPLE_ADDRESS(state, value) {
      Vue.set(state, "simple_address", value);
      SEARCH.generateURLQuery();
    },
    SET_SEARCH_OPTIONS(state, value) {
      let original = state.store_search_options;
      let obj = {
        ...original,
        ...value
      };
      Vue.set(state, "store_search_options", obj);
      SEARCH.generateURLQuery();
    },
    SET_SEARCH_RADIUS(state, value) {
      let clone = Object.assign(state.store_search_options);
      clone.search_radius = value;
      Vue.set(state, "store_search_options", clone);
    },
    SET_TRANSIT_PREF(state, value) {
      state.store_search_options.transportation_preference = value.toUpperCase();
    },
    SET_STORIES(state, value) {
      state.stories = value;
    },
    SET_TAXONOMY_FROM_API(state, value) {
      state.taxonomy_from_api = value;
    },
    SET_TAXONOMY_CODES(state, value) {
      Vue.set(state, "taxonomy_codes", value);
    },
    SET_LOCATIONS(state, value) {
      Vue.set(state, "locations", value);
    },
    SET_API_SERVICES(state, value) {
      Vue.set(state, "api_services", value);
    },
    ADD_TO_API_SERVICES(state, value) {
      let clone = Object.assign(state.api_services);
      clone.push(value);
      Vue.set(state, "api_services", value);
    },
    SET_RESEARCH_OPTIONS(state, value) {
      let original = state.store_research_options;
      let obj = {
        ...original,
        ...value
      };
      console.log(obj);
      Vue.set(state, "store_research_options", obj);
    }
  },
  getters: {
    isPreloaded(state) {
      return state.isPreloaded;
    },
    isSearching(state) {
      return state.isSearching;
    },
    hasResults(state, getters) {
      return getters.services.length > 0;
    },
    simple_address(state) {
      return state.simple_address;
    },
    isReadyToSearch(state, getters) {
      let hasRealAddress = getters.store_search_address ? true : false;
      let hasRadius = getters.store_search_options.search_radius ? true : false;
      let hasBounds = getters.bounds ? true : false;
      return hasRealAddress && hasRadius && hasBounds;
    },
    showSuggestService() {
      return process.env.VUE_APP_SUGGEST_SERVICE == "1";
    },
    domain(state) {
      return state.domain;
    },
    hotline(state) {
      return state.hotline;
    },
    stories(state) {
      return state.stories;
    },
    transportation_preference(state) {
      return state.store_search_options.transportation_preference;
    },
    store_search_address(state) {
      return state.store_search_options.search_address;
    },
    formatted_address(state) {
      if (
        state.store_search_options &&
        state.store_search_options.search_address
      ) {
        return state.store_search_options.search_address.formatted_address;
      }
      return null;
    },
    store_research_options(state) {
      return state.store_research_options;
    },
    store_search_options(state) {
      return state.store_search_options;
    },
    hasSearch(state) {
      return state.store_search_options != null;
    },
    hasAddress(state) {
      return state.store_search_options.search_address ? true : false;
    },
    active_filters(state) {
      let primary = state.store_search_options.primary_types;
      let secondary = state.store_search_options.secondary_types;
      return primary.concat(secondary);
    },
    active_regular_search_filters(state, getters) {
      return getters.active_filters
        .map(a => state.taxonomy_codes.find(t => t.id == a))
        .filter(a => !a.special);
    },
    active_regular_OR_search_filters(state) {
      return state.store_search_options.primary_types
        .map(a => state.taxonomy_codes.find(t => t.id == a))
        .filter(a => !a.special);
    },
    active_regular_AND_search_filters(state) {
      return state.store_search_options.secondary_types
        .map(a => state.taxonomy_codes.find(t => t.id == a))
        .filter(a => !a.special);
    },
    active_special_search_filters(state, getters) {
      return getters.active_filters
        .map(a => state.taxonomy_codes.find(t => t.id == a))
        .filter(a => a.special);
    },
    // RESEARCH-RELATED
    active_research_filters(state) {
      let primary = state.store_research_options.primary_types;
      let secondary = state.store_research_options.secondary_types;
      return primary.concat(secondary);
    },
    active_regular_research_filters(state, getters) {
      return getters.active_research_filters
        .map(a => state.taxonomy_codes.find(t => t.id == a))
        .filter(a => !a.special);
    },
    active_regular_OR_research_filters(state) {
      return state.store_research_options.primary_types
        .map(a => state.taxonomy_codes.find(t => t.id == a))
        .filter(a => !a.special);
    },
    active_regular_AND_research_filters(state) {
      return state.store_research_options.secondary_types
        .map(a => state.taxonomy_codes.find(t => t.id == a))
        .filter(a => !a.special);
    },
    active_special_research_filters(state, getters) {
      return getters.active_research_filters
        .map(a => state.taxonomy_codes.find(t => t.id == a))
        .filter(a => a.special);
    },

    taxonomy_from_api(state) {
      return state.taxonomy_from_api;
    },
    taxonomy_codes(state) {
      return state.taxonomy_codes;
    },
    store_search_possible_options(state) {
      return state.store_search_possible_options;
    },
    service_primary_types(state) {
      return state.taxonomy_codes.filter(a => a.type == "primary" && a.id);
    },
    service_secondary_types(state) {
      return state.taxonomy_codes.filter(a => a.type == "secondary" && a.id);
    },
    locations(state) {
      return state.locations;
    },
    api_services(state) {
      return state.api_services;
    },
    bounds(state) {
      return state.store_search_options.search_bounds;
    },
    current_radius(state) {
      return state.store_search_options.search_radius;
    },
    current_radius_object(state, getters) {
      let current = getters.current_radius;
      let options = state.store_search_possible_options.radius;
      return options.find(a => a.value == current);
    },
    maximum_radius(state) {
      let r = state.store_search_possible_options.radius;
      return r[r.length - 1].value;
    },
    minimum_radius(state) {
      let r = state.store_search_possible_options.radius;
      return r[0].value;
    },
    default_radius(state) {
      let r = state.store_search_possible_options.radius.find(a => a.default);
      return r.value;
    },
    default_primary(state, getters) {
      return getters.taxonomy_codes
        .filter(a => a.type == "primary" && a.default)
        .map(a => a.id);
    },
    default_secondary(state, getters) {
      return getters.taxonomy_codes
        .filter(a => a.type == "secondary" && a.default)
        .map(a => a.id);
    },
    filter_for_open_today(state, getters) {
      return getters.active_special_search_filters.find(a => a.code == "OPEN");
    },
    research_services(state, getters) {
      return getters.api_services
        .filter(service => service)
        .map(service => {
          let loc = service.service_at_location;
          if (loc && loc[0]) {
            service.location = {
              lat: loc[0].latitude,
              lng: loc[0].longitude
            };
          }
          return service;
        });
    },
    services(state, getters) {
      let s = getters.api_services;

      // we have no api_services then return an empty array
      if (s.length == 0) return [];
      // fill in each service so it has all the extra information we need
      s = s.map(service => {
        if (service.complete) {
          return service;
        }
        service.complete = false;

        service.location = {
          lat: service.service_at_location[0].latitude,
          lng: service.service_at_location[0].longitude,
          location_id: service.service_at_location[0].location_id
        };

        if (getters.store_search_address) {
          service.distance = new Location({
            destination: service.location
          }).calcDistanceToService();
        }

        let cal = new CalendarClass(service);
        let currentSlot = cal.currentSlot();
        let nextSlot = cal.nextSlot();
        service.isOpen = currentSlot ? true : false;
        service.isOpenToday = currentSlot || nextSlot ? true : false;
        service.currentSlot = currentSlot;
        service.nextSlot = nextSlot;
        service.nextDay = cal.nextDay();
        service.holidayCalendar = cal.holidayCalendar();
        service.weeklyCalendar = cal.weeklyCalendar();
        service.complete = true;
        return service;
      });

      // Needs filtering for open today?
      if (s.length > 0 && getters.filter_for_open_today) {
        s = s.filter(service => service.isOpenToday);
      }

      return s.sort((a, b) => {
        if (a.distance > b.distance) {
          return 1;
        } else if (a.distance < b.distance) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  },
  actions: {
    initialize({commit, getters, dispatch}) {
      if (getters.store_search_options && getters.simple_address) {
        // console.warn("We already have store search options, do not initialize");
        return;
      }
      let defaults = {};
      if (router && !_.isEmpty(router.history.current.query)) {
        let original = router.history.current.query;
        if (original.p) {
          if (!Array.isArray(original.p)) {
            original.p = original.p.split(",");
          }
        } else {
          original.p = [];
        }
        if (original.s) {
          if (!Array.isArray(original.s)) {
            original.s = original.s.split(",");
          }
        } else {
          original.s = [];
        }
        defaults = original;
      } else {
        defaults = {
          a: null,
          p: getters.default_primary,
          s: getters.default_secondary
        };
      }
      let radius = defaults.r || getters.default_radius;
      let primary = defaults.p || [];
      let secondary = defaults.s || [];

      let obj = {
        search_radius: radius,
        primary_types: primary,
        secondary_types: secondary
      };
      commit("SET_SEARCH_OPTIONS", obj);
      commit("SET_RESEARCH_OPTIONS", obj);
      dispatch("updateSimpleAddress", defaults.a);
    },
    updateIsSearching({commit}, value) {
      commit("SET_IS_SEARCHING", value);
    },
    updateIsPreloaded({commit}, value) {
      commit("SET_IS_PRELOADED", value);
    },
    updateSimpleAddress({commit}, address) {
      commit("SET_SIMPLE_ADDRESS", address);
      new Location()
        .getAddressAndBoundingBox(address)
        .then(result => {
          commit("SET_SEARCH_OPTIONS", result);
        })
        .catch(() => {});
    },
    updateSearchOptions({commit}, search) {
      commit("SET_SEARCH_OPTIONS", search);
    },
    updateSearchAddress({commit}, address) {
      commit("SET_SEARCH_OPTIONS", {search_address: address});
    },
    updatePrimarySearchOptions({commit}, search) {
      commit("SET_SEARCH_OPTIONS", {primary_types: search});
    },
    updateSecondarySearchOptions({commit}, search) {
      commit("SET_SEARCH_OPTIONS", {secondary_types: search});
    },
    updateSearchBounds({commit}, search) {
      commit("SET_SEARCH_OPTIONS", {search_bounds: search});
    },
    updateSearchRadius({commit}, radius) {
      commit("SET_SEARCH_OPTIONS", {search_radius: radius});
    },
    updateTransitPref({commit}, pref) {
      commit("SET_SEARCH_OPTIONS", {transportation_preference: pref});
    },
    updateStories({commit}, stories) {
      commit("SET_STORIES", stories);
    },
    updateResults({commit}, results) {
      commit("SET_SEARCH_RESULTS", results);
    },
    updateTaxonomyFromAPI({commit}, value) {
      commit("SET_TAXONOMY_FROM_API", value);
    },
    updateTaxonomyCodes({commit}, value) {
      commit("SET_TAXONOMY_CODES", value);
    },
    updateLocations({commit}, value) {
      commit("SET_LOCATIONS", value);
    },
    updateAPIServices({commit}, value) {
      commit("SET_API_SERVICES", value);
    },
    addToAPIServices({commit}, value) {
      commit("ADD_TO_API_SERVICES", value);
    },
    resetSearchAddress({commit, getters}) {
      commit("SET_API_SERVICES", []);
      commit("SET_SEARCH_OPTIONS", {
        search_address: null,
        search_radius: getters.maximum_radius,
        primary_types: getters.default_primary,
        secondary_types: getters.default_secondary
      });
      commit("SET_SIMPLE_ADDRESS", null);
    },
    updatePrimaryResearchOptions({commit}, search) {
      commit("SET_RESEARCH_OPTIONS", {primary_types: search});
    },
    updateSecondaryResearchOptions({commit}, search) {
      commit("SET_RESEARCH_OPTIONS", {secondary_types: search});
    },
    resetResearchOptions({commit, getters}) {
      commit("SET_RESEARCH_OPTIONS", {
        primary_types: getters.default_primary,
        secondary_types: getters.default_secondary
      });
    }
  }
});
