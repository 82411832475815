<template>
<section class="requirements module"
         v-if="hasAny">
  <header>
    <span>
      Requirements
    </span>
  </header>

  <ul>
    <li v-for="requirement in requirements"
        :key="requirement.id">
      <!-- <Icon type="Warning"></Icon>  -->
      {{requirement.document}}
    </li>
    <li v-for="eligibility in eligibility"
        :key="eligibility.id">
      <!-- <Icon type="Warning"></Icon>  -->
      {{eligibility.eligibility}}
    </li>
  </ul>

</section>
</template>

<script>
export default {
  name: "Requirements",
  components: {},
  props: [ "service" ],
  data() {
    return {}
  },
  computed: {
    hasAny() {
      return this.requirements.concat( this.eligibility ).length > 0
    },
    requirements() {
      return this.service.required_documents.filter( a => a.document != "" && a.document != null )
    },
    eligibility() {
      return this.service.eligibility.filter( a => a.eligibility != "" && a.eligibility != null )
    },
  }
};
</script>
<style scoped lang="scss">
.requirements {
    @include module;
    li {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: auto 1fr;
        .icon {
            margin: 0.25rem 0;
            height: 1rem;
            color: theme-color-level(primary,2);
        }

    }
}
</style>
